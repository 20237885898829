export const LOG_ADMIN = "LOG_ADMIN";
export const LOGOUT_ADMIN = "LOGOUT_ADMIN";

export const FETCH_PAYMENT_DETAIL = "FETCH_PAYMENT_DETAIL";
export const FETCH_USERS = "FETCH_USERS";
export const FETCH_ACTIVITIES = "FETCH_ACTIVITIES";

export const FETCH_SUBSCRIPTION = "FETCH_SUBSCRIPTION";
export const FETCH_DOWNLOAD = "FETCH_DOWNLOAD";
export const FETCH_WHOLE_STAT = "FETCH_WHOLE_STAT";

export const VERIFIED_TOGGLE = "VERIFIED_TOGGLE";
export const REJECTED_TOGGLE = "REJECTED_TOGGLE";
export const AUTOMATED_TOGGLE = "AUTOMATED_TOGGLE";
export const MANUAL_TOGGLE = "MANUAL_TOGGLE";

export const ALL_PAYMENT_DETAILS = "ALL_PAYMENT_DETAILS";

export const USER_SEARCH_QUERY = "USER_SEARCH_QUERY";

export const SCHOOL_SEARCH_QUERY = "SCHOOL_SEARCH_QUERY";

export const EXAM_SEARCH_QUERY = "EXAM_SEARCH_QUERY";
export const WITHDRAWAL_TRANSACTION_QUERY = "WITHDRAWAL_TRANSACTION_QUERY";

export const PENDING_WITHDRAWAL = "PENDING_WITHDRAWAL";
export const ONORDER_WITHDRAWAL = "ONORDER_WITHDRAWAL";
export const SUCCESS_WITHDRAWAL = "SUCCESS_WITHDRAWAL";
export const DECLINED_WITHDRAWAL = "DECLINED_WITHDRAWAL";
