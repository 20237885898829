import React from "react";

const ChapaTransactionDetail = ({
  transaction = {},
  chapaTransaction = {},
}) => {
  return (
    <div style={styles.container}>
      {[
        ...Object.keys(transaction)
          .map((key) => {
            const value = transaction[key];
            if (value) {
              return {
                label: key?.toUpperCase(),
                value: value,
              };
            }
            return null;
          })
          .filter((e) => e), // Filter out null values
        {
          label: "Chapa Transfer Detail",
          value: "",
        },
        ...Object.keys(chapaTransaction)
          .map((key) => {
            const value = chapaTransaction[key];
            if (value) {
              return {
                label: key?.toUpperCase(),
                value: value,
              };
            }
            return null;
          })
          .filter((e) => e), // Filter out null values
      ].map(({ label, value }) => {
        return (
          <div key={label} style={styles.row}>
            <span style={styles.label}>{label}:</span>
            <span style={styles.value}>{value.toString()}</span>
          </div>
        );
      })}
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    width: "100%",
  },
  row: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    padding: "8px 0", // Adjust padding as needed
    borderBottom: "1px solid #ddd",
    fontSize: ".8rem",
  },
  label: {
    fontWeight: "bold",
    color: "#333",
    flex: 1,
    textAlign: "left",
  },
  value: {
    color: "#555",
    textAlign: "right",
    flex: 2, // Adjust flex ratio to balance space
  },
};

export default ChapaTransactionDetail;
