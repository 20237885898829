import React from "react";

const TransactionDetail = ({ transaction }) => {
  return (
    <div style={styles.container}>
      {[
        { label: "Transaction ID", value: transaction.transactionId },
        { label: "Amount", value: `$${transaction.amount.toFixed(2)}` },
        { label: "Level", value: transaction.level },
        { label: "Status", value: transaction.status },
        {
          label: "Created At",
          value: new Date(transaction.createdAt).toLocaleString(),
        },
        { label: "Is Withdraw", value: transaction.isWithdraw ? "Yes" : "No" },
        { label: "Type", value: transaction.type },
        {
          label: "Inviter",
          value: (
            <a href={`/users/${transaction.inviterId}`}>
              {transaction.inviterId}
            </a>
          ),
        },
      ].map(({ label, value }) => (
        <div key={label} style={styles.row}>
          <span style={styles.label}>{label}:</span>
          <span style={styles.value}>{value}</span>
        </div>
      ))}
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    width: "100%",
  },
  row: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    padding: "8px 0", // Adjust padding as needed
    borderBottom: "1px solid #ddd",
    fontSize: ".8rem",
  },
  label: {
    fontWeight: "bold",
    color: "#333",
    flex: 1,
    textAlign: "left",
  },
  value: {
    color: "#555",
    textAlign: "right",
    flex: 2, // Adjust flex ratio to balance space
  },
};

export default TransactionDetail;
