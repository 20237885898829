import React, { useState } from "react";
import { BsFillMenuButtonWideFill, BsAlarm } from "react-icons/bs";
import moment from "moment";

import {
  AiOutlineBranches,
  AiOutlineReload,
  AiOutlineUser,
} from "react-icons/ai";
import BasicTitle from "../../common/BasicTitle";
import { useNavigate } from "react-router-dom";
import { RiDownloadCloudFill } from "react-icons/ri";
import InfiniteScroll from "../../infiniteScroll/InfiniteScroll";

function RecentDownloaders(props) {
  const [url, setUrl] = useState("/admin/api/recent-downloaders");
  const refreshData = () => {
    setUrl((url) => (url.endsWith("/") ? url.slice(0, -1) : url + "/"));
  };

  return (
    <div className="user-detail-page">
      <div className="user-detail-content-wrapper">
        <div className="user-detail-header">
          <h1 className="common-pages-title"> Recent Downloaders </h1>
          <div className="user-detail-refresh-wrapper">
            <button
              className="user-detail-refresh-icon-button"
              onClick={refreshData}
            >
              Refresh
              <AiOutlineReload className="user-detail-refresh-icon" />
            </button>
          </div>
        </div>

        <InfiniteScroll query={""} url={url} dataKey={"recentDownloaders"}>
          {function (recentDownloaders, lastElementRef) {
            return recentDownloaders.length === 0 ? (
              <div className="page-loading-wrapper">
                <div className="no-data-wrapper">
                  <BsFillMenuButtonWideFill className="no-data-icon" />

                  <BasicTitle title="There is no user detail in the docket" />
                </div>
              </div>
            ) : (
              <>
                {recentDownloaders.map((recentDownloader, index) => (
                  <RecentDownloader
                    key={recentDownloader.uid}
                    recentDownloader={recentDownloader}
                    lastElementRef={
                      index === recentDownloaders.length - 1
                        ? lastElementRef
                        : null
                    }
                  />
                ))}
              </>
            );
          }}
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default RecentDownloaders;

function RecentDownloader({ recentDownloader, lastElementRef }) {
  const navigator = useNavigate();
  const { fullname, grade, uid, isSubscribed } = recentDownloader;
  const downloadInformation = JSON.parse(
    recentDownloader?.downloadInformation ?? null
  );
  console.log(downloadInformation);
  const timing =
    moment(
      new Date(downloadInformation?.sort((a, b) => a - b)[0]?.date)
    ).fromNow() === "Invalid date"
      ? "No download"
      : moment(
          new Date(
            downloadInformation?.sort((a, b) => b.date - a.date)[0]?.date
          )
        ).fromNow();

  return (
    <div
      className="user-detail-card"
      onClick={() => navigator("/users/" + uid)}
      ref={lastElementRef}
    >
      <div className="upper-user-card">
        <h5 className="user-card-name">
          <AiOutlineUser className="user-card-name-icon" />
          {fullname}
          <span className="user-card-grade">
            {" "}
            Grade:
            {grade === "grade8"
              ? "8"
              : grade === "grade12n"
              ? "12 Natural"
              : grade === "grade12s"
              ? "12 Social"
              : grade}{" "}
            <AiOutlineBranches className="user-card-grade-icon" />{" "}
          </span>
        </h5>
        <div className="user-card-time">
          {" "}
          <BsAlarm className="user-card-time-icon" /> {timing}
        </div>
      </div>
      <div className="bottom-user-card">
        <div className="user-card-email">
          Download in 10 min:{" "}
          {downloadInformation?.filter((download) => {
            return moment(new Date())
              .subtract({ minutes: 10 })
              .isBefore(moment(new Date(download?.date)));
          }).length ?? 0}{" "}
        </div>
        <div className="user-card-email">
          <RiDownloadCloudFill /> {downloadInformation?.length ?? 0}
        </div>
      </div>

      {isSubscribed === true && (
        <div className={"verification-flag verification-flag-" + true}>
          {" "}
          Subscribed
        </div>
      )}
    </div>
  );
}
