import React, { useState } from "react";
import "./payment-detail.css";
import {
  BsFillMenuButtonWideFill,
  BsAlarm,
  BsStopCircle,
} from "react-icons/bs";
import moment from "moment";

import { AiOutlineReload, AiOutlineUser } from "react-icons/ai";
import BasicTitle from "../../common/BasicTitle";
import { Link } from "react-router-dom";
import InfiniteScroll from "../../infiniteScroll/InfiniteScroll";
import { useProviderContext } from "../../provider/appProvider";
function PaymentDetail(props) {
  const {
    state: { verified, rejected, automated, manual, allPaymentDetails },
    toggleVerified: setVerified,
    toggleRejected: setRejected,
    toggleAutomated,
    toggleManual,
    toggleAllPaymentDetails,
  } = useProviderContext();
  // const [rejected, setRejected] = useState(false);
  // const [verified, setVerified] = useState(false);
  const [url, setUrl] = useState("/admin/api/payment-details");

  const refreshData = () => {
    setUrl((url) => (url.endsWith("/") ? url.slice(0, -1) : url + "/"));
  };

  return (
    <div className="payment-detail-page">
      <div className="payment-detail-content-wrapper">
        <div className="payment-detail-header">
          <h1 className="common-pages-title"> Payment detail </h1>
          <div className="payment-detail-refresh-wrapper">
            <button
              className="payment-detail-refresh-icon-button"
              onClick={refreshData}
            >
              Refresh
              <AiOutlineReload className="payment-detail-refresh-icon" />
            </button>
            <BsFillMenuButtonWideFill className="payment-detail-icon" />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
            padding: "8px 2px 2px 2px",
          }}
        >
          <button
            onClick={(e) => setVerified((val) => !val)}
            className="verified-button"
          >
            <div> Verified</div>
            <input
              className="verified-check-box"
              readOnly={true}
              type="checkbox"
              checked={verified}
              onClick={(e) => null}
            />
          </button>
          <button
            onClick={(e) => setRejected((val) => !val)}
            className="rejected-button"
          >
            <div> Rejected </div>
            <input
              readOnly={true}
              className="rejected-check-box"
              type="checkbox"
              checked={rejected}
              onClick={(e) => null}
            />
          </button>

          <button
            onClick={(e) => toggleAutomated((val) => !val)}
            className="verified-button"
            style={{
              backgroundColor: "#7176d6",
            }}
          >
            <div> Automated</div>
            <input
              className="verified-check-box"
              readOnly={true}
              type="checkbox"
              checked={automated}
              onClick={(e) => null}
            />
          </button>

          <button
            onClick={(e) => toggleManual((val) => !val)}
            className="verified-button"
            style={{
              backgroundColor: "rgb(231, 81, 11)",
            }}
          >
            <div> Manual </div>
            <input
              className="verified-check-box"
              readOnly={true}
              type="checkbox"
              checked={manual}
              onClick={(e) => null}
            />
          </button>

          <button
            onClick={(e) => toggleAllPaymentDetails((val) => !val)}
            className="verified-button"
            style={{
              backgroundColor: "navy",
            }}
          >
            <div> All </div>
            <input
              className="verified-check-box"
              readOnly={true}
              type="checkbox"
              checked={allPaymentDetails}
              onClick={(e) => null}
            />
          </button>
        </div>
        {!verified &&
          !rejected &&
          !automated &&
          !manual &&
          !allPaymentDetails && (
            <BasicTitle title="Payment details that did not assigned status." />
          )}

        {allPaymentDetails && <BasicTitle title="All Payment details." />}
        <InfiniteScroll
          url={url}
          additionalParams={
            verified || rejected || automated || manual || allPaymentDetails
              ? `verified=${verified}&rejected=${rejected}&automated=${automated}&manual=${manual}&allPaymentDetails=${allPaymentDetails}`
              : null
          }
          dataKey={"paymentDetails"}
        >
          {function (paymentDetails, lastElementRef) {
            return paymentDetails.length === 0 ? (
              <div className="page-loading-wrapper">
                <div className="no-data-wrapper">
                  <BsFillMenuButtonWideFill className="no-data-icon" />

                  <BasicTitle title="There is no payment detail in the docket" />
                </div>
              </div>
            ) : (
              <>
                {paymentDetails.map((detail, index) => (
                  <PaymentDetailCard
                    key={detail.id}
                    detail={detail}
                    lastElementRef={
                      index === paymentDetails.length - 1
                        ? lastElementRef
                        : null
                    }
                  />
                ))}
              </>
            );
          }}
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default PaymentDetail;

function PaymentDetailCard({
  detail: {
    users: { fullname, phone },
    bank,
    plan,
    createdAt,
    id,
    isRenewing,
    verified,
    reject,
    automation,
  },
  lastElementRef,
}) {
  const timing = moment(createdAt).fromNow();
  return (
    <Link
      className="payment-detail-card"
      to={"/payment-details/" + id}
      ref={lastElementRef}
    >
      <div className="upper-payment-card">
        <h5 className="payment-card-name">
          <AiOutlineUser className="payment-card-name-icon" />
          {fullname}
          <span className="payment-card-grade">
            {" "}
            Grade:{" "}
            {plan === "grade8"
              ? "8"
              : plan === "grade12n"
              ? "12 Natural"
              : plan === "grade12s"
              ? "12 Social"
              : plan}{" "}
            <BsStopCircle className="payment-card-grade-icon" />{" "}
          </span>
        </h5>
        <div className="payment-card-time">
          {" "}
          <BsAlarm className="payment-card-time-icon" /> {timing}
        </div>
      </div>
      <div className="bottom-payment-card">
        <div className="payment-card-phone">{phone}</div>

        <div className="payment-card-bank">{bank}</div>
      </div>
      <div>
        {(verified || reject) && (
          <div>
            <span
              className={
                verified
                  ? "status-tag verified-tag "
                  : "status-tag rejected-tag "
              }
            >
              {" "}
              {verified ? "Verified" : "Rejected"}{" "}
            </span>
          </div>
        )}
      </div>

      {automation ? (
        <div className="automation-tag">Automation</div>
      ) : (
        <div className="manual-tag">Manual</div>
      )}
      {isRenewing ? <div className="renewing-tag"> Renewing </div> : null}
    </Link>
  );
}
