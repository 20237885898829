import React from "react";

const ListTile = ({
  leading,
  title,
  subtitle,
  trailing,
  color = "#007bff",
  info, // New prop for the top-right small grey text
}) => {
  const containerStyle = {
    display: "flex",
    alignItems: "center",
    padding: "10px 5px",
    borderBottom: "1px solid #eee",
    cursor: "pointer",
    position: "relative", // Added for positioning the info text
    transition: "all 0.2s ease",
  };

  const avatarStyle = {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    backgroundColor: color,
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: ".9rem",
    fontWeight: "bold",
    marginRight: "15px",
  };

  const contentStyle = {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  };

  const titleStyle = {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "5px",
  };

  const subtitleStyle = {
    fontSize: "14px",
    color: "#666",
  };

  const trailingStyle = {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: ".9rem",
  };

  const infoStyle = {
    position: "absolute",
    top: "0",
    right: "2px",
    fontSize: ".72rem",
    color: "#999", // Grey color for the info text
  };

  return (
    <div style={containerStyle} title={info} className="list-tile">
      {leading && <div style={avatarStyle}>{leading}</div>}
      <div style={contentStyle}>
        <div style={titleStyle}>{title}</div>
        <div style={subtitleStyle}>{subtitle}</div>
      </div>
      {info && <div style={infoStyle}>{info}</div>}
      {trailing && <div style={trailingStyle}>{trailing}</div>}
    </div>
  );
};

export default ListTile;
