import React, { useEffect, useState } from "react";
import { AiOutlineReload } from "react-icons/ai";
import useFetch from "../../hooks/useFetch";
import Loader from "../../common/Loader";
import "./admin.css";
import axios from "axios";
import { useProviderContext } from "../../provider/appProvider";
import { toast } from "react-toastify";
import BasicTitle from "../../common/BasicTitle";
import { BsFillMenuButtonWideFill } from "react-icons/bs";
function Admin(props) {
  const { logAdmin } = useProviderContext();
  const { data, error, refreshData } = useFetch("/admin/api/cred");
  const [prevPassword, setPrevPassword] = useState("");
  const [password, setPassword] = useState("");
  const [secret, setSecret] = useState("");
  const [diabled, setDiabled] = useState(true);

  const [username, setUsername] = useState(data?.admin?.username ?? "");
  useEffect(() => {
    setUsername(data?.admin?.username ?? "");
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const resp = await axios.post("/admin/api/cred", {
        prevPassword: prevPassword,
        username:
          username !== data.admin.username && username.length > 5
            ? username
            : undefined,
        password: password.length >= 6 ? password : undefined,
        secret,
      });
      console.log(resp);
      const admin = resp.data.admin;
      const accessToken = resp.data.accessToken;
      const message = resp.data.message;

      logAdmin(admin, accessToken);
      toast.success(message ?? "Successfully done.");
      setUsername("");
      refreshData();
      setPrevPassword("");
      setPassword("");
      // refreshData();
    } catch (err) {
      console.log(err.status, err);
      const status = err.response.status;
      if (status && status.toString().startsWith("4")) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };
  return (
    <div className="user-detail-page">
      <div className="user-detail-content-wrapper">
        <div className="user-detail-header">
          <h1 className="common-pages-title"> Admin detail</h1>
          <div className="user-detail-refresh-wrapper">
            <button
              className="user-detail-refresh-icon-button"
              onClick={refreshData}
            >
              Refresh
              <AiOutlineReload className="user-detail-refresh-icon" />
            </button>
          </div>
        </div>
        {data == null ? (
          <div className="page-loading-wrapper">
            <Loader width={100} height={120} color={"darkblue"} />
          </div>
        ) : (
          <div className="admin-form-wrapper">
            <form>
              <div className="form-label">Username</div>
              <input
                className="admin-form-input"
                value={username}
                onChange={(e) => {
                  if (
                    e.target.value !== data.admin.username &&
                    username.length > 5 &&
                    prevPassword.length >= 6
                  ) {
                    setDiabled(false);
                  } else {
                    setDiabled(true);
                  }
                  setUsername(e.target.value);
                }}
                placeholder="Username"
              />
              <div className="form-label">Previous password</div>
              <input
                className="admin-form-input"
                value={prevPassword}
                onChange={(e) => {
                  if (
                    e.target.value.length >= 6 &&
                    ((username !== data.admin.username &&
                      username.length > 5) ||
                      password.length >= 6)
                  ) {
                    setDiabled(false);
                  } else {
                    setDiabled(true);
                  }
                  setPrevPassword(e.target.value);
                }}
                placeholder="Previous Password"
              />
              <div className="form-label">Password</div>
              <input
                className="admin-form-input"
                value={password}
                onChange={(e) => {
                  if (e.target.value.length >= 6 && prevPassword.length >= 6) {
                    setDiabled(false);
                  } else {
                    setDiabled(true);
                  }
                  setPassword(e.target.value);
                }}
                placeholder="Password"
              />

              <div className="form-label">Secret</div>
              <input
                className="admin-form-input"
                value={secret}
                onChange={(e) => {
                  setSecret(e.target.value);
                }}
                placeholder="Secret"
              />
              <button
                className="update-button"
                disabled={diabled}
                onClick={handleSubmit}
              >
                Update Configuration
              </button>
            </form>
          </div>
        )}
        {error && (
          <div className="page-loading-wrapper">
            <div className="no-data-wrapper">
              <BsFillMenuButtonWideFill className="no-data-icon" />

              <BasicTitle title="Something went wrong!!" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Admin;
