import React, { useState } from "react";
import "./allUsers.css";
import { BsFillMenuButtonWideFill, BsAlarm } from "react-icons/bs";
import moment from "moment";

import {
  AiFillSketchSquare,
  AiOutlineBranches,
  AiOutlineCloudUpload,
  AiOutlineContacts,
  AiOutlineMinusCircle,
  AiOutlinePhone,
  AiOutlineReload,
  AiOutlineUser,
} from "react-icons/ai";
import { useProviderContext } from "../../provider/appProvider";
import BasicTitle from "../../common/BasicTitle";
import { Link } from "react-router-dom";
import InfiniteScroll from "../../infiniteScroll/InfiniteScroll";

function AlllUsers(props) {
  const {
    state: { userSearch: search },
    searchUser: setSearch,
  } = useProviderContext();
  const [url, setUrl] = useState("/admin/api/users");
  const refreshData = () => {
    setUrl((url) => (url.endsWith("/") ? url.slice(0, -1) : url + "/"));
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleVcfDownload = async (users) => {
    // downloadVCF(users);
  };

  return (
    <div className="user-detail-page">
      <div className="user-detail-content-wrapper">
        <div className="user-detail-header">
          <h1 className="common-pages-title"> user detail </h1>
          <div className="user-detail-refresh-wrapper">
            <button
              className="user-detail-refresh-icon-button"
              onClick={refreshData}
            >
              Refresh
              <AiOutlineReload className="user-detail-refresh-icon" />
            </button>
          </div>
        </div>
        <form className="search-tool-wrapper">
          <input
            type="text"
            value={search}
            onChange={(e) => handleSearch(e)}
            className="user-search-input"
            placeholder="search"
          />
        </form>
        <div className="vcf-exporter-holder">
          <button
            type="submit"
            className="vcf-export-button"
            onClick={() => handleVcfDownload()}
          >
            {" "}
            <div> Export VCF </div>{" "}
            <div>
              {" "}
              <AiOutlineContacts className="vcf-export-icon" />{" "}
            </div>
          </button>
        </div>

        <InfiniteScroll url={url} query={search} dataKey={"users"}>
          {function (users, lastElementRef) {
            return users.length === 0 ? (
              <div className="page-loading-wrapper">
                <div className="no-data-wrapper">
                  <BsFillMenuButtonWideFill className="no-data-icon" />

                  <BasicTitle title="There is no user detail in the docket" />
                </div>
              </div>
            ) : (
              <>
                {users.map((user, index) => (
                  <UserDetailCard
                    key={user.uid}
                    user={user}
                    search={search}
                    lastElementRef={
                      index === users.length - 1 ? lastElementRef : null
                    }
                  />
                ))}
              </>
            );
          }}
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default AlllUsers;

export function UserDetailCard({
  user: {
    fullname,
    phone,
    grade,
    createdAt,
    uid,
    email,
    isVerified,
    freeTrialEnds,
    schools,
    isSubscribed,
  },
  search,
  lastElementRef,
}) {
  const timing = moment(createdAt).fromNow();
  return (
    <Link
      className="user-detail-card"
      to={"/users/" + uid}
      ref={lastElementRef}
    >
      <div className="upper-user-card">
        <h5 className="user-card-name">
          <AiOutlineUser className="user-card-name-icon" />
          <SearchGoogle from={fullname} search={search} />
          <span className="user-card-grade">
            {" "}
            Grade:
            {grade === "grade8"
              ? "8"
              : grade === "grade12n"
              ? "12 Natural"
              : grade === "grade12s"
              ? "12 Social"
              : grade}{" "}
            <AiOutlineBranches className="user-card-grade-icon" />{" "}
          </span>
        </h5>
        <div className="user-card-time">
          {" "}
          <BsAlarm className="user-card-time-icon" /> {timing}
        </div>
      </div>
      <div className="bottom-user-card">
        <div
          className="user-card-phone"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            <SearchGoogle from={phone} search={search} />
          </div>
          <div
            style={{
              fontSize: "0.85rem",
            }}
          >
            <h4
              style={{
                fontSize: "0.85rem",
                fontWeight: "bold",
              }}
            >
              SCHOOL:{" "}
            </h4>
            {schools?.schoolName && (
              <SearchGoogle
                from={`${schools?.schoolName}`.toUpperCase()}
                search={search}
              />
            )}
          </div>
        </div>

        <div className="user-card-email">
          <SearchGoogle from={email} search={search} />{" "}
        </div>
      </div>
      <div
        className={`verification-flag verification-flag-${isVerified ? 1 : 0}`}
      >
        {" "}
        {isVerified ? "Verified" : "Not Verified"}{" "}
      </div>
      {createdAt === freeTrialEnds && (
        <div className={"free-trial-decline-indicators"}>
          {/* <AiOutlineMobile color="white" /> */}
          <span
            style={{
              fontSize: "0.8rem",
              color: "white",
            }}
          >
            <AiOutlineMinusCircle color="white" size={18} />
          </span>
        </div>
      )}

      {isSubscribed && (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            padding: "2px 4px",
          }}
        >
          <AiFillSketchSquare color="rgba(100,255,120,1)" size={28} />
        </div>
      )}
    </Link>
  );
}

export function SearchGoogle({ from, search }) {
  return (
    <>
      {search.length
        ? from
            .toLowerCase()
            .split(search.toLowerCase())
            .join(`|${search}|`)
            .split("|")
            .map((term, i) => {
              return (
                <span
                  className={
                    search.toLowerCase() === term.toLowerCase()
                      ? "search-term-mark"
                      : ""
                  }
                  key={term + "-" + i}
                >
                  {term.toUpperCase()}
                </span>
              );
            })
        : from}
    </>
  );
}
