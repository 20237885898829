import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useProviderContext } from "../provider/appProvider";
import { useNavigate } from "react-router-dom";

function useFetch(url) {
  const navigator = useNavigate();
  const { logoutAdmin } = useProviderContext();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const fetchData = useCallback(() => {
    setLoading(true);
    axios
      .get(url)
      .then((res) => {
        setLoading(false);
        console.log("Request", res.data);
        setData(res.data);
      })
      .catch(async (err) => {
        setError(true);

        if (err?.response?.status === 401 || err?.response?.status === 403) {
          const isLoggedOut = await logoutAdmin(false, fetchData);
          if (isLoggedOut) {
            toast.warn("Your loggin session is expired");
            navigator("/login");
          }
          return;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url, logoutAdmin, navigator]);

  const refreshData = () => fetchData();

  useEffect(() => {
    fetchData();
  }, [url, fetchData]);

  return { data, loading, error, refreshData };
}

export default useFetch;
