import React, { useState } from "react";
import { BsFillMenuButtonWideFill, BsAlarm } from "react-icons/bs";
import moment from "moment";
import "./withdrawalRequests.css";
import { AiOutlineReload, AiOutlineUser } from "react-icons/ai";
import BasicTitle from "../../common/BasicTitle";
import { Link } from "react-router-dom";
import InfiniteScroll from "../../infiniteScroll/InfiniteScroll";
import { useProviderContext } from "../../provider/appProvider";
import { SearchGoogle } from "../AllUsers/AlllUsers";
function WithdrawRequests(props) {
  const {
    state: {
      transactionSearch,

      pendingWithdrawal,
      onorderWithdrawal,
      successWithdrawal,
      declinedWithdrawal,
    },
    searchWithdrawalTransaction,

    togglePendingWithdrawal,
    toggleSuccessWithdrawal,
    toggleDeclinedWithdrawal,
    toggleOnorderWithdrawal,
  } = useProviderContext();
  // const [rejected, setRejected] = useState(false);
  // const [verified, setVerified] = useState(false);
  const [url, setUrl] = useState("/admin/api/withdrawal-transactions");
  const handleSearch = (e) => {
    searchWithdrawalTransaction(e.target.value);
  };

  const refreshData = () => {
    setUrl((url) => (url.endsWith("/") ? url.slice(0, -1) : url + "/"));
  };

  return (
    <div className="payment-detail-page">
      <div className="payment-detail-content-wrapper">
        <div className="payment-detail-header">
          <h1 className="common-pages-title"> Withdrawal transactions </h1>
          <div className="payment-detail-refresh-wrapper">
            <button
              className="payment-detail-refresh-icon-button"
              onClick={refreshData}
            >
              Refresh
              <AiOutlineReload className="payment-detail-refresh-icon" />
            </button>
            <BsFillMenuButtonWideFill className="payment-detail-icon" />
          </div>
        </div>
        <form className="search-tool-wrapper">
          <input
            type="text"
            value={transactionSearch}
            onChange={(e) => handleSearch(e)}
            className="user-search-input"
            placeholder="search"
          />
        </form>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
            padding: "8px 2px 2px 2px",
          }}
        >
          <button
            onClick={(e) => togglePendingWithdrawal((val) => !val)}
            className="pending-button"
          >
            <div> Pending</div>
            <input
              className="verified-check-box"
              readOnly={true}
              type="checkbox"
              checked={pendingWithdrawal}
              onClick={(e) => null}
            />
          </button>

          <button
            onClick={(e) => toggleOnorderWithdrawal((val) => !val)}
            className="onorder-button"
          >
            <div> Onorder </div>
            <input
              readOnly={true}
              className="rejected-check-box"
              type="checkbox"
              checked={onorderWithdrawal}
              onClick={(e) => null}
            />
          </button>
          <button
            onClick={(e) => toggleDeclinedWithdrawal((val) => !val)}
            className="rejected-button"
          >
            <div> Declined </div>
            <input
              readOnly={true}
              className="rejected-check-box"
              type="checkbox"
              checked={declinedWithdrawal}
              onClick={(e) => null}
            />
          </button>

          <button
            onClick={(e) => toggleSuccessWithdrawal((val) => !val)}
            className="verified-button"
          >
            <div> Success </div>
            <input
              readOnly={true}
              className="rejected-check-box"
              type="checkbox"
              checked={successWithdrawal}
              onClick={(e) => null}
            />
          </button>
        </div>

        <InfiniteScroll
          url={url}
          additionalParams={
            pendingWithdrawal ||
            successWithdrawal ||
            onorderWithdrawal ||
            declinedWithdrawal ||
            transactionSearch
              ? `pendingWithdrawal=${pendingWithdrawal}&successWithdrawal=${successWithdrawal}&onorderWithdrawal=${onorderWithdrawal}&declinedWithdrawal=${declinedWithdrawal}&transactionSearch=${transactionSearch}`
              : null
          }
          dataKey={"withdrawalTransactions"}
        >
          {function (withdrawalTransactions, lastElementRef) {
            return withdrawalTransactions.length === 0 ? (
              <div className="page-loading-wrapper">
                <div className="no-data-wrapper">
                  <BsFillMenuButtonWideFill className="no-data-icon" />

                  <BasicTitle title="There is no withdrawal transactions in the docket" />
                </div>
              </div>
            ) : (
              <>
                {withdrawalTransactions.map((transaction, index) => (
                  <WithdrawalTransactionCard
                    key={transaction.transactionId}
                    transaction={transaction}
                    transactionSearch={transactionSearch}
                    lastElementRef={
                      index === withdrawalTransactions.length - 1
                        ? lastElementRef
                        : null
                    }
                  />
                ))}
              </>
            );
          }}
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default WithdrawRequests;

function WithdrawalTransactionCard({
  transaction,
  lastElementRef,
  transactionSearch,
}) {
  const timing = moment(transaction.createdAt).fromNow();
  return (
    <Link
      className="payment-detail-card"
      to={"/withdrawal-transaction/" + transaction.transactionId}
      ref={lastElementRef}
    >
      {/* {JSON.stringify(transaction)} */}
      <div className="upper-payment-card">
        <h5 className="payment-card-name">
          <AiOutlineUser className="payment-card-name-icon" />
          <SearchGoogle
            from={transaction.wallet.users.fullname}
            search={transactionSearch}
          />{" "}
          <span className="withdrwal-transaction-bank-name">
            <SearchGoogle
              from={transaction.bankName}
              search={transactionSearch}
            />{" "}
          </span>
        </h5>
        <div className="payment-card-time">
          {" "}
          <BsAlarm className="payment-card-time-icon" /> {timing}
        </div>
      </div>
      <div className="bottom-payment-card">
        <div className="payment-card-phone">
          Tx.Ref:{" "}
          <SearchGoogle
            from={transaction.autoTransferReference}
            search={transactionSearch}
          />{" "}
        </div>

        <div className="payment-card-bank">
          <div
            className="profile-grade"
            style={{
              fontWeight: 900,
              color:
                transaction.status === "pending" ||
                transaction.status === "onorder"
                  ? "orange"
                  : transaction.status === "declined"
                  ? "crimson"
                  : null,
            }}
          >
            Status: {transaction.status}
          </div>{" "}
          Amount: {transaction.amount} ETB
        </div>
      </div>
    </Link>
  );
}
