import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./withdraw-form.css"; // We'll define some custom styles here
import useFetch from "../../hooks/useFetch";
import { toast } from "react-toastify";
import axios from "axios";
import { useProviderContext } from "../../provider/appProvider";
import Swal from "sweetalert2";

const WithdrawForm = ({ schoolCode, removePopup, refreshPageData }) => {
  const { logoutAdmin } = useProviderContext();
  const { data, refreshData } = useFetch("/admin/api/schools/" + schoolCode);
  const [balance, setBalance] = useState(null);
  const ValidationSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError("Amount must be a number")
      .required("Amount is required")
      .min(11, "Amount must be greater than 10")
      .max(
        balance ?? 100,
        "Amount must be less than balance " + balance + " ETB"
      ),
    reason: Yup.string()
      .required("Reason is required")
      .min(10, "Reason must be at least 10 characters long"),
    bankNo: Yup.number()
      .typeError("Bank Number must be a number")
      .required("Bank Number is required")
      .min(8, "Bank Number must be at least 8 digits long"),
  });
  useEffect(() => {
    setBalance(data?.school?.balance);
  }, [data]);
  console.log(data);

  const handleSubmit = async (values, data) => {
    const { setSubmitting } = data;
    setSubmitting(true);
    try {
      const resp = await axios.post(`/admin/api//withdraw-school-bonus`, {
        schoolCode,
        amount: values.amount,
        reason: values.reason,
        bankNo: values.bankNo?.toString(),
      });
      console.log(resp);
      if (resp.status === 200) {
        toast.success(resp.data.message);
        refreshData();
      } else {
        throw new Error(resp.data.message ?? "Something went wrong!!");
      }
    } catch (err) {
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        const isLoggedOut = await logoutAdmin(false, () =>
          handleSubmit(values, data)
        );
        if (isLoggedOut) {
          toast.warn("Your loggin session is expired");
          navigator("/login");
        }
        return;
      }
      console.log(err);
      toast.error(err?.response?.data?.message ?? "Something went wrong!!");
    } finally {
      // data.resetForm();

      setSubmitting(null);
      removePopup();
      refreshPageData();
      setTimeout(() => {
        Swal.fire({
          title: "Success!",
          text: "Your withdrawal request has been submitted successfully.",
          icon: "success",
          confirmButtonText: "OK",
        });
      }, 400);
    }
  };
  return (
    <div
      style={{
        margin: "0 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Formik
        initialValues={{ amount: "", reason: "", bankNo: "" }}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="cute-form-container">
            <div
              style={{
                textAlign: "right",
                fontWeight: "bold",
              }}
            >
              Balane: {data ? data?.school?.balance + " ETB" : "--"}
            </div>
            <div className="cute-form-group">
              <label htmlFor="amount">Amount</label>
              <Field
                type="number"
                name="amount"
                placeholder="Enter amount"
                className="cute-form-input"
              />
              <ErrorMessage
                name="amount"
                component="div"
                className="error-message"
              />
            </div>

            <div className="cute-form-group">
              <label htmlFor="reason">Reason</label>
              <Field
                as="textarea"
                name="reason"
                placeholder="Enter the reason"
                className="cute-form-textarea"
              />
              <ErrorMessage
                name="reason"
                component="div"
                className="error-message"
              />
            </div>

            <div className="cute-form-group">
              <label htmlFor="bankNo">Bank Number</label>
              <Field
                type="number"
                name="bankNo"
                placeholder="Enter your bank number"
                className="cute-form-input"
              />
              <ErrorMessage
                name="bankNo"
                component="div"
                className="error-message"
              />
            </div>

            <button
              type="submit"
              className="cute-form-button"
              disabled={isSubmitting}
            >
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default WithdrawForm;
