import React, { useState } from "react";
import { BsFillMenuButtonWideFill } from "react-icons/bs";

import {
  AiFillHome,
  AiOutlineReload,
  AiOutlineUser,
  AiOutlineVerified,
} from "react-icons/ai";
import { useProviderContext } from "../../provider/appProvider";
import BasicTitle from "../../common/BasicTitle";
import { Link } from "react-router-dom";
import InfiniteScroll from "../../infiniteScroll/InfiniteScroll";

function AllSchools(props) {
  const {
    state: { schoolSearch: search },
    searchSchool: setSearch,
  } = useProviderContext();
  const [url, setUrl] = useState("/admin/api/schools");
  const refreshData = () => {
    setUrl((url) => (url.endsWith("/") ? url.slice(0, -1) : url + "/"));
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className="user-detail-page">
      <div className="user-detail-content-wrapper">
        <div className="user-detail-header">
          <h1 className="common-pages-title"> Schools </h1>
          <div className="user-detail-refresh-wrapper">
            <button
              className="user-detail-refresh-icon-button"
              onClick={refreshData}
            >
              Refresh
              <AiOutlineReload className="user-detail-refresh-icon" />
            </button>
          </div>
        </div>
        <form className="search-tool-wrapper">
          <input
            type="text"
            value={search}
            onChange={(e) => handleSearch(e)}
            className="user-search-input"
            placeholder="search"
          />
        </form>

        <InfiniteScroll url={url} query={search} dataKey={"schools"}>
          {function (schools, lastElementRef) {
            return schools.length === 0 ? (
              <div className="page-loading-wrapper">
                <div className="no-data-wrapper">
                  <BsFillMenuButtonWideFill className="no-data-icon" />

                  <BasicTitle title="There is no user detail in the docket" />
                </div>
              </div>
            ) : (
              <>
                {schools.map((school, index) => (
                  <SchoolDetailCard
                    a={school}
                    key={school.schoolCode}
                    school={school}
                    search={search}
                    lastElementRef={
                      index === schools.length - 1 ? lastElementRef : null
                    }
                  />
                ))}
              </>
            );
          }}
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default AllSchools;

function SchoolDetailCard({
  school: { schoolCode, schoolName, users, verified, balance, school_agents },
  search,
  lastElementRef,
  a,
}) {
  console.log(a);
  return (
    <Link
      className="user-detail-card"
      to={"/schools/" + schoolCode}
      ref={lastElementRef}
    >
      <div className="upper-user-card">
        <h5 className="user-card-name">
          <AiFillHome className="user-card-name-icon" />
          <SearchGoogle from={schoolName} search={search} />
          <span
            className="user-card-grade"
            style={{
              fontWeight: "bold",
            }}
          >
            <AiOutlineUser
              className="user-card-grade-icon"
              style={{
                margin: "1px 5px 1px 1px",
              }}
            />{" "}
            {school_agents?.filter((agent) => agent?.superAgent)[0]?.fullname +
              " Super Agent"}
          </span>
        </h5>
        <div className="user-card-time">
          {" "}
          <AiOutlineVerified className="user-card-time-icon" />{" "}
          {verified ? "Verified" : "Not Verified"}{" "}
        </div>
      </div>
      <div className="bottom-user-card">
        <div className="user-card-phone">Total Students: {users.length}</div>
        <div className="user-card-email">Balance: {balance} ETB</div>
      </div>
    </Link>
  );
}

export function SearchGoogle({ from, search }) {
  return (
    <>
      {search.length
        ? from
            .toLowerCase()
            .split(search.toLowerCase())
            .join(`|${search}|`)
            .split("|")
            .map((term, i) => {
              return (
                <span
                  className={
                    search.toLowerCase() === term.toLowerCase()
                      ? "search-term-mark"
                      : ""
                  }
                  key={term + "-" + i}
                >
                  {term}
                </span>
              );
            })
        : from}
    </>
  );
}
