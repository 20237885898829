import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useProviderContext } from "../provider/appProvider";
import { useNavigate } from "react-router-dom";

export default function useDataQuery(
  url,
  query,
  pageNumber,
  dataKey,
  additionalParams = null
) {
  const navigator = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const { logoutAdmin } = useProviderContext();

  useEffect(() => {
    setData(null);
  }, [query, additionalParams, url]);
  const fetchData = useCallback(() => {
    setLoading(true);
    setError(false);
    let cancel;
    if (additionalParams) {
      const params = new URLSearchParams(additionalParams);
      // Convert to object
      additionalParams = Object.fromEntries(params.entries());
    } else {
      additionalParams = {};
    }
    axios({
      method: "GET",
      url: url,
      params: { q: query, page: pageNumber, ...additionalParams },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        setData((prevData) => {
          if (!prevData) {
            return [...new Set([...res.data[dataKey].map((e) => e)])];
          }
          return [
            ...new Set([...prevData, ...res.data[dataKey].map((e) => e)]),
          ];
        });
        setHasMore(res.data[dataKey].length > 0);
        setLoading(false);
      })
      .catch(async (e) => {
        if (axios.isCancel(e)) return;
        if (e?.response?.status === 401 || e?.response?.status === 403) {
          const isLoggedOut = await logoutAdmin(false, fetchData);
          if (isLoggedOut) {
            toast.warn("Your loggin session is expired");
            navigator("/login");
            console.log("logged out");
          }
          return;
        }
        setError(true);
        setLoading(false);
      });
    return () => cancel();
  }, [url, query, pageNumber, dataKey, additionalParams]);
  useEffect(() => {
    return fetchData();
  }, [fetchData]);

  return { loading, error, data, hasMore, retry: fetchData };
}
