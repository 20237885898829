import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useProviderContext } from "../../provider/appProvider";
import { toast } from "react-toastify";
import Loader from "../../common/Loader";

// Validation schema
const validationSchema = Yup.object({
  duration: Yup.number()
    .required("Duration is required")
    .positive("Must be positive"),
  release: Yup.date().required("Release date is required"),
  subject: Yup.string().required("Subject is required"),
  grade: Yup.string().required("Grade is required"),
  title: Yup.string().required("Title is required"),
  type: Yup.string().required("Type is required"),
  file: Yup.mixed()
    .required("A file is required")
    .test("fileFormat", "Only ZIP files are allowed", (value) => {
      return value && value.type === "application/zip";
    }),
});

const FancyForm = () => {
  const [isLoading, setLoading] = React.useState(false);
  const { logoutAdmin } = useProviderContext();
  const fileInputRef = React.useRef();
  const handleSubmit = async (values, { resetForm }) => {
    // Handle form submission
    const clearForm = () => {
      // Clear the file input field manually
      resetForm();
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
    };
    try {
      setLoading(true);
      const formData = new FormData();

      for (const key in values) {
        formData.append(key, values[key]);
      }

      const resp = await axios.post("/admin/api/add-exam", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(resp);

      setLoading(false);
      toast.success(resp.data.message);
      clearForm();
    } catch (err) {
      console.log(err);
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        const isLoggedOut = await logoutAdmin(false, () =>
          handleSubmit(values, { resetForm })
        );
        if (isLoggedOut) {
          toast.warn("Your loggin session is expired");
          navigator("/login");
        }
        return;
      }
      toast.error(
        err?.response?.data?.message ??
          err?.response?.data ??
          "Something went wrong!!"
      );
      // clearForm();
      setLoading(false);
    }
  };
  return (
    <Formik
      initialValues={{
        duration: "",
        release: "",
        subject: "",
        grade: "",
        title: "",
        type: "main",
        file: null,
        free: false,
        isTrial: false,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, errors, touched }) => (
        <Form className="max-w-lg mx-auto p-4 space-y-6 bg-white shadow-md rounded-lg">
          <div>
            <label
              htmlFor="grade"
              className="block text-sm font-medium text-gray-700"
            >
              Grade
            </label>
            <Field
              name="grade"
              as="select"
              className={`mt-1 block w-full px-3 py-2 border ${
                touched.grade && errors.grade
                  ? "border-red-500"
                  : "border-gray-300"
              } rounded-md shadow-sm focus:outline-none focus:ring focus:ring-indigo-200`}
            >
              <option value="">Select a grade</option>
              <option value="grade8">Grade 8</option>
              <option value="grade10">Grade 10</option>
              <option value="grade12n">Grade 12 (Natural)</option>
              <option value="grade12s">Grade 12 (Social)</option>
            </Field>
            <ErrorMessage
              name="grade"
              component="div"
              className="text-red-600 text-sm mt-1"
            />
          </div>

          <div>
            <label
              htmlFor="subject"
              className="block text-sm font-medium text-gray-700"
            >
              Subject
            </label>
            <Field
              name="subject"
              as="select"
              className={`mt-1 block w-full px-3 py-2 border ${
                touched.subject && errors.subject
                  ? "border-red-500"
                  : "border-gray-300"
              } rounded-md shadow-sm focus:outline-none focus:ring focus:ring-indigo-200`}
            >
              <option value="">Select a subject</option>
              <option value="amharic">አማርኛ</option>
              <option value="citizenship">Citizenship</option>
              <option value="english">English</option>
              <option value="generalscience">General Science</option>
              <option value="math">Math (Grade 8)</option>
              <option value="social">Social Studies</option>
              <option value="biology">Biology</option>
              <option value="chemistry">Chemistry</option>
              <option value="civic">Civic</option>
              <option value="geography">Geography</option>
              <option value="history">History</option>
              <option value="physics">Physics</option>
              <option value="economics">Economics</option>
              <option value="aptitude">Aptitude</option>
              <option value="mathNatural">Math (Natural)</option>
              <option value="mathSocial">Math (Social)</option>
            </Field>
            <ErrorMessage
              name="subject"
              component="div"
              className="text-red-600 text-sm mt-1"
            />
          </div>

          <div>
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700"
            >
              Title
            </label>
            <Field
              name="title"
              className={`mt-1 block w-full px-3 py-2 border ${
                touched.title && errors.title
                  ? "border-red-500"
                  : "border-gray-300"
              } rounded-md shadow-sm focus:outline-none focus:ring focus:ring-indigo-200`}
            />
            <ErrorMessage
              name="title"
              component="div"
              className="text-red-600 text-sm mt-1"
            />
          </div>

          <div>
            <label
              htmlFor="type"
              className="block text-sm font-medium text-gray-700"
            >
              Type
            </label>
            <Field
              name="type"
              as="select"
              className={`mt-1 block w-full px-3 py-2 border ${
                touched.type && errors.type
                  ? "border-red-500"
                  : "border-gray-300"
              } rounded-md shadow-sm focus:outline-none focus:ring focus:ring-indigo-200`}
            >
              <option value="">Select a type</option>
              <option value="main">Main</option>
              <option value="model">Model</option>
              <option value="free">Free</option>
            </Field>
            <ErrorMessage
              name="type"
              component="div"
              className="text-red-600 text-sm mt-1"
            />
          </div>
          <div>
            <label
              htmlFor="release"
              className="block text-sm font-medium text-gray-700"
            >
              Release Date
            </label>
            <Field
              name="release"
              type="datetime-local"
              className={`mt-1 block w-full px-3 py-2 border ${
                touched.release && errors.release
                  ? "border-red-500"
                  : "border-gray-300"
              } rounded-md shadow-sm focus:outline-none focus:ring focus:ring-indigo-200`}
            />
            <ErrorMessage
              name="release"
              component="div"
              className="text-red-600 text-sm mt-1"
            />
          </div>
          <div>
            <label
              htmlFor="duration"
              className="block text-sm font-medium text-gray-700"
            >
              Duration (hours)
            </label>
            <Field
              name="duration"
              type="number"
              className={`mt-1 block w-full px-3 py-2 border ${
                touched.duration && errors.duration
                  ? "border-red-500"
                  : "border-gray-300"
              } rounded-md shadow-sm focus:outline-none focus:ring focus:ring-indigo-200`}
            />
            <ErrorMessage
              name="duration"
              component="div"
              className="text-red-600 text-sm mt-1"
            />
          </div>

          <div>
            <label
              htmlFor="file"
              className="block text-sm font-medium text-gray-700"
            >
              Upload File (ZIP only)
            </label>
            <input
              id="file"
              name="file"
              type="file"
              ref={fileInputRef}
              accept=".zip"
              onChange={(event) => {
                setFieldValue("file", event.currentTarget.files[0]);
              }}
              className={`mt-1 block w-full px-3 py-2 border ${
                touched.file && errors.file
                  ? "border-red-500"
                  : "border-gray-300"
              } rounded-md shadow-sm focus:outline-none focus:ring focus:ring-indigo-200`}
            />
            <ErrorMessage
              name="file"
              component="div"
              className="text-red-600 text-sm mt-1"
            />
          </div>

          <div className="flex items-center">
            <Field
              name="free"
              type="checkbox"
              className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
            />
            <label htmlFor="free" className="ml-2 block text-sm text-gray-900">
              Free
            </label>
          </div>

          <div className="flex items-center">
            <Field
              name="isTrial"
              type="checkbox"
              className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
            />
            <label
              htmlFor="isTrial"
              className="ml-2 block text-sm text-gray-900"
            >
              Trial
            </label>
          </div>

          <div>
            <button
              type="submit"
              className="w-full py-2 px-4 bg-indigo-600 hover:bg-indigo-700 text-white font-semibold rounded-md shadow-md focus:outline-none focus:ring focus:ring-indigo-200"
            >
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <Loader width={55} height={55} />{" "}
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FancyForm;
