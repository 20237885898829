import React, { useCallback, useEffect, useState, useRef } from "react";
import { BsFillMenuButtonWideFill } from "react-icons/bs";
import Toggle from "react-toggle";
import Editor from "@monaco-editor/react";
import useFetch from "../../hooks/useFetch";
import Loader from "../../common/Loader";
import BasicTitle from "../../common/BasicTitle";
import { useNavigate, useParams } from "react-router-dom";
import "./examcooksingle.css";
import {
  AiOutlineFullscreen,
  AiOutlineFullscreenExit,
  AiOutlineLoading,
  AiOutlineReload,
  AiOutlineSave,
} from "react-icons/ai";
import { toast } from "react-toastify";
import QuestionCard from "./displayExam/Question";
import { parseText } from "./displayExam/utils/utils";
import { APP_SERVER } from "../../../global";
import { getAccessToken } from "../../util/accessToken";
import { useProviderContext } from "../../provider/appProvider";
import axios from "axios";
function ExamCookSingle(props) {
  const { eid } = useParams();
  const { data, loading, error, refreshData } = useFetch(
    "/admin/api/exams/" + eid
  );
  return (
    <div className="exam-edit-content-wrapper">
      {loading && !data && !error && (
        <div className="page-loading-wrapper">
          <div className="page-loading-wrapper">
            <Loader
              width={80}
              height={40}
              color={"#7175D6"}
              containerStyle={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          </div>
        </div>
      )}
      {data && <ExamCookSingleBody data={data} refreshData={refreshData} />}
      {error && (
        <div className="page-loading-wrapper">
          <div className="no-data-wrapper">
            <BsFillMenuButtonWideFill className="no-data-icon" />
            <BasicTitle title="Something went wrong!!" />
          </div>
        </div>
      )}
    </div>
  );
}

export default ExamCookSingle;

function ExamCookSingleBody({ data, refreshData }) {
  const { logoutAdmin } = useProviderContext();
  const [viewMode, setViewMode] = useState(false);
  const navigator = useNavigate();
  const examInfo = data.examInfo;
  const exam = data.exam;
  const [theme, setTheme] = useState("nightOwlDark");
  const [editorContent, setEditorContent] = useState(
    JSON.stringify(examInfo.examData, null, 2)
  );
  const [isSaving, setIsSaving] = useState(false);
  const [initialValue, setInitialValue] = useState(
    JSON.stringify(examInfo.examData, null, 2)
  );
  const [isFullScreen, setIsFullScreen] = useState(false);
  const editorContainerRef = useRef(null);
  // useEffect(() => {
  //   setEditorContent(initialValue);
  // }, [initialValue, viewMode]);
  useEffect(() => {
    if (data && data.examInfo) {
      // console.log("Updated");
      setInitialValue(JSON.stringify(data.examInfo.examData, null, 2));
      setEditorContent(JSON.stringify(data.examInfo.examData, null, 2));
    }
  }, [data]);
  // console.log("Data", data);

  const handleSave = useCallback(async () => {
    try {
      JSON.parse(editorContent);
    } catch (e) {
      toast.error(<div> {e.message} </div>, {
        position: "bottom-right",
        autoClose: 2000,
      });
    }
    if (isSaving) {
      toast.info("We are saving current change.", {
        position: "bottom-right",
        autoClose: 2000,
      });
      return;
    }

    setIsSaving(true);
    try {
      const examData = JSON.parse(editorContent);

      const resp = await axios.post("/admin/api/update-exam-content", {
        eid: exam.eid,
        examData,
      });
      await refreshData(); // Refresh data after save
      toast.success(resp.data.message, {
        position: "bottom-right",
        autoClose: 3000,
      });
    } catch (err) {
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        const isLoggedOut = await logoutAdmin(false, handleSave);
        if (isLoggedOut) {
          toast.warn("Your loggin session is expired");
          navigator("/login");
        }
        return;
      }

      toast.error(err.message);
    } finally {
      setIsSaving(false);
    }
  }, [editorContent, exam.eid, isSaving, logoutAdmin, navigator, refreshData]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "s") {
        if (initialValue === editorContent) {
          toast.info("No change detected.", {
            position: "bottom-right",
            autoClose: 2000,
          });
          e.preventDefault();
          return;
        }
        e.preventDefault();
        handleSave();
      }

      if (e.key === "Escape" && isFullScreen) {
        e.preventDefault();
        exitFullScreen();
      }
    };

    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        // Exit full-screen mode
        setIsFullScreen(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullscreenChange
      );
    };
  }, [editorContent, handleSave, initialValue, isFullScreen]);

  const toggleTheme = () => {
    setTheme((prevTheme) =>
      prevTheme === "nightOwlDark" ? "nightOwlLight" : "nightOwlDark"
    );
  };

  const handleEditorChange = (value) => {
    setEditorContent(value);
  };

  const enterFullScreen = () => {
    if (editorContainerRef.current) {
      if (editorContainerRef.current.requestFullscreen) {
        editorContainerRef.current.requestFullscreen();
      } else if (editorContainerRef.current.mozRequestFullScreen) {
        editorContainerRef.current.mozRequestFullScreen();
      } else if (editorContainerRef.current.webkitRequestFullscreen) {
        editorContainerRef.current.webkitRequestFullscreen();
      } else if (editorContainerRef.current.msRequestFullscreen) {
        editorContainerRef.current.msRequestFullscreen();
      }
      setIsFullScreen(true);
    }
  };

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };
  const declareTheme = () => {
    window.monaco.editor.defineTheme("nightOwlDark", {
      base: "vs-dark",
      inherit: true,
      rules: [
        { token: "", foreground: "d6deeb", background: "011627" },
        { token: "comment", foreground: "637777", fontStyle: "italic" },
        { token: "keyword", foreground: "c792ea" },
        { token: "string", foreground: "ecc48d" },
        { token: "number", foreground: "f78c6c" },
        { token: "regexp", foreground: "ecc48d" },
        { token: "operator", foreground: "89ddff" },
        { token: "namespace", foreground: "82aaff" },
        { token: "type", foreground: "82aaff" },
        { token: "delimiter", foreground: "d6deeb" },
        { token: "tag", foreground: "7fdbca" },
        { token: "attribute.name", foreground: "80cbc4" },
      ],
      colors: {
        "editor.background": "#011627",
        "editor.foreground": "#d6deeb",
        "editorCursor.foreground": "#80a4c2",
        "editorLineNumber.foreground": "#3b536a",
        "editorLineNumber.activeForeground": "#c792ea",
        "editor.selectionBackground": "#1d3b53",
        "editor.inactiveSelectionBackground": "#1d3b53",
        "editor.lineHighlightBackground": "#00000050",
        "editorWhitespace.foreground": "#3b536a",
        "editorIndentGuide.background": "#2b91af",
        "editorIndentGuide.activeBackground": "#6d9cbe",
      },
    });

    window.monaco.editor.defineTheme("nightOwlLight", {
      base: "vs",
      inherit: true,
      rules: [
        { token: "", foreground: "403f53", background: "ffffff" },
        { token: "comment", foreground: "6a737d", fontStyle: "italic" },
        { token: "keyword", foreground: "d73a49" },
        { token: "string", foreground: "032f62" },
        { token: "number", foreground: "005cc5" },
        { token: "regexp", foreground: "032f62" },
        { token: "operator", foreground: "d73a49" },
        { token: "namespace", foreground: "6f42c1" },
        { token: "type", foreground: "005cc5" },
        { token: "delimiter", foreground: "403f53" },
        { token: "tag", foreground: "22863a" },
        { token: "attribute.name", foreground: "d73a49" },
      ],
      colors: {
        "editor.background": "#ffffff",
        "editor.foreground": "#403f53",
        "editorCursor.foreground": "#80a4c2",
        "editorLineNumber.foreground": "#abb2bf",
        "editorLineNumber.activeForeground": "#d73a49",
        "editor.selectionBackground": "#d0d7de",
        "editor.inactiveSelectionBackground": "#d0d7de",
        "editor.lineHighlightBackground": "#f6f8fa",
        "editorWhitespace.foreground": "#e1e4e8",
        "editorIndentGuide.background": "#e1e4e8",
        "editorIndentGuide.activeBackground": "#e36209",
      },
    });
  };
  const handleRevert = () => {
    setEditorContent(initialValue);
  };
  // console.log(examInfo);
  return (
    <div
      className="editing-tools"
      ref={editorContainerRef}
      style={{
        position: isFullScreen ? "fixed" : "relative",
        top: isFullScreen ? 0 : "auto",
        left: isFullScreen ? 0 : "auto",
        right: isFullScreen ? 0 : "auto",
        bottom: isFullScreen ? 0 : "auto",
        width: isFullScreen ? "100vw" : "100%",
        height: isFullScreen ? "100vh" : "85vh",
        zIndex: isFullScreen ? 1000 : "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          color: isFullScreen ? "white" : "#333",
        }}
      >
        <div className="exam-info">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <h2> Title: </h2> {exam?.structure[0]?.title}{" "}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <h2> Grade Code: </h2> {exam.grade}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <h2> Asset Images: </h2> {examInfo?.assets?.length}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <h2> {"View Mode"} </h2>{" "}
            <Toggle
              defaultChecked={viewMode}
              onChange={async () => {
                try {
                  await logoutAdmin();
                } catch (err) {}
                setViewMode((viewMode) => !viewMode);
              }}
              style={{ width: "40px", background: "#7176d6" }}
              color="#7176d6"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <button
            onClick={handleRevert}
            disabled={initialValue === editorContent}
            title="Revert"
            style={{
              marginLeft: "auto",
              padding: "5px 10px",
              backgroundColor:
                initialValue === editorContent ? "#ccc" : "#7175D6",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              width: "40px",
              height: "40px",
              display: "flex",
              margin: "0 10px 0 0",
            }}
          >
            <AiOutlineReload size={32} />
          </button>
          <button
            onClick={handleSave}
            disabled={isSaving || initialValue === editorContent}
            title="Save"
            style={{
              marginLeft: "auto",
              padding: "5px 10px",
              backgroundColor:
                isSaving || initialValue === editorContent ? "#ccc" : "#7175D6",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: isSaving ? "not-allowed" : "pointer",
              width: "40px",
              height: "40px",
              display: "flex",
            }}
          >
            {isSaving ? (
              <AiOutlineLoading size={32} />
            ) : (
              <AiOutlineSave size={32} />
            )}
          </button>
          <button
            title="Full Screen"
            onClick={isFullScreen ? exitFullScreen : enterFullScreen}
            className="editor-icon-button"
          >
            {isFullScreen ? (
              <AiOutlineFullscreenExit size={32} className="editor-icon" />
            ) : (
              <AiOutlineFullscreen size={32} className="editor-icon" />
            )}
          </button>
          <div title="Dark Mode">
            <Toggle
              defaultChecked={theme === "nightOwlDark"}
              onChange={toggleTheme}
              aria-label="Dark Mode"
              style={{ width: "40px", background: "#7176d6" }}
              color="#7176d6"
            />
          </div>
        </div>
      </div>

      {viewMode ? (
        <div className="phone-screen-mode">
          <div className="selfie-camera"> </div>
          {examInfo?.examData?.structure
            ? examInfo?.examData?.structure?.map((structure) => {
                const questions = examInfo.examData.questions.slice(
                  structure?.start - 1,
                  structure?.end
                );
                const questionsGroup = questions.map((question) => {
                  return (
                    <QuestionCard
                      questionData={question}
                      exam={exam}
                      key={question.question}
                      isFullScreen={isFullScreen}
                    />
                  );
                });
                questionsGroup.unshift(
                  <div key={"top-bar"}>
                    <div className="structure-container">
                      {structure.title && (
                        <h4
                          style={{
                            fontWeight: 900,
                          }}
                        >
                          {parseText(structure.title)}
                        </h4>
                      )}
                      {structure?.image && (
                        <div>
                          {" "}
                          <img
                            src={
                              APP_SERVER +
                              "/admin/" +
                              "exam-image/" +
                              exam.eid +
                              "/" +
                              structure?.image?.image +
                              `?accessToken=${getAccessToken()}`
                            }
                            className="image-constraints"
                            alt={structure?.image?.title ?? ""}
                          />{" "}
                        </div>
                      )}
                      {structure.direction && (
                        <h4>Direction: {parseText(structure.direction)}</h4>
                      )}
                      {structure.passage &&
                        structure?.passage?.text?.map((paragraph, i) => {
                          if (i === 0) {
                            return (
                              <div key={paragraph}>
                                <h2
                                  style={{
                                    fontWeight: 900,
                                  }}
                                >
                                  {parseText(structure?.passage?.title)}
                                </h2>
                                <div className="passage-paragraph">
                                  {" "}
                                  {parseText(paragraph)}
                                </div>
                              </div>
                            );
                          }
                          return (
                            <div className="passage-paragraph">
                              {" "}
                              {parseText(paragraph)}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
                return questionsGroup;
              })
            : examInfo.examData.questions.map((question) => {
                return (
                  <QuestionCard
                    questionData={question}
                    exam={exam}
                    key={question.question}
                    isFullScreen={isFullScreen}
                  />
                );
              })}
        </div>
      ) : (
        <Editor
          className="vscode-editor"
          defaultLanguage="json"
          theme={theme}
          value={editorContent}
          onChange={handleEditorChange}
          beforeMount={() => {
            declareTheme();
          }}
        />
      )}
    </div>
  );
}
