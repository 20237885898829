import React, { useState, useRef, useCallback, useEffect } from "react";
import useDataQuery from "./useDataQuery";
import Loader from "../common/Loader";
import "./infiniteScroll.css";
import { AiOutlineReload } from "react-icons/ai";
export default function InfiniteScroll({
  url,
  query,
  children,
  dataKey,
  additionalParams = null,
  page = 1,
}) {
  const [pageNumber, setPageNumber] = useState(page);

  const { data, hasMore, loading, error, retry } = useDataQuery(
    url,
    query,
    pageNumber,
    dataKey,
    additionalParams
  );
  const handleRetry = () => {
    retry();
  };

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && error === false) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, error]
  );

  useEffect(() => {
    setPageNumber(1);
  }, [query, additionalParams, url]);

  return (
    <>
      {data && children(data, lastElementRef)}
      {loading && pageNumber === 1 && (
        <div className="page-loading-wrapper">
          <Loader width={100} height={120} color={"darkblue"} />
        </div>
      )}

      <div
        style={{
          textAlign: "center",
        }}
      >
        {
          <div
            className="loading-wrapper"
            style={{
              height: "40px",
            }}
          >
            {loading && pageNumber !== 1 && <div> Loading... </div>}
          </div>
        }
      </div>

      {error && pageNumber === 1 && (
        <div className="page-error-wrapper">
          <div className="page-error-box">
            <div>Loading went wrong.</div>
            <button className="retry-button" onClick={handleRetry}>
              {" "}
              <AiOutlineReload
                className="user-detail-refresh-icon"
                style={{
                  margin: "0 4px 0 0",
                  width: "16px",
                }}
              />{" "}
              Retry
            </button>
          </div>
        </div>
      )}

      <div>
        {error && pageNumber !== 1 && (
          <button className="retry-button" onClick={handleRetry}>
            {" "}
            <AiOutlineReload
              className="user-detail-refresh-icon"
              style={{
                margin: "0 4px 0 0",
                width: "16px",
              }}
            />{" "}
            Retry
          </button>
        )}
      </div>
    </>
  );
}
