import React, { useEffect, useState } from "react";
import { BsFillMenuButtonWideFill } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Loader from "../../common/Loader";
import BasicTitle from "../../common/BasicTitle";
import ReactDOMServer from "react-dom/server";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  AiFillBook,
  AiFillPhone,
  AiFillPlusCircle,
  AiFillUnlock,
  AiOutlineDeleteColumn,
  AiOutlineFieldTime,
  AiOutlineMail,
  AiOutlinePushpin,
  AiOutlineReload,
  AiOutlineUser,
  AiTwotoneHome,
} from "react-icons/ai";
import moment from "moment";
import "./singlePaymentCard.css";
import axios from "axios";
import { APP_SERVER } from "../../../global";
import { getAccessToken } from "../../util/accessToken";
import { useProviderContext } from "../../provider/appProvider";
import { toast } from "react-toastify";
import ListTile from "./ListTile";
import TransactionDetail from "./TransactionDetail";
import ChapaTransactionDetail from "../SingleWithdrawalTransaction/ChapaTransactionDetail";

const MySwal = withReactContent(Swal);

function SinglePaymentDetail(props) {
  const { paymentId } = useParams();
  const { data, loading, error, refreshData } = useFetch(
    "/admin/api/payment-details/" + paymentId
  );

  return (
    <div className="payment-detail-page">
      <div className="payment-detail-content-wrapper">
        {loading && (
          <div className="page-loading-wrapper">
            <div className="page-loading-wrapper">
              <Loader
                width={80}
                height={40}
                color={"#7175D6"}
                containerStyle={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            </div>
          </div>
        )}
        {data && (
          <SinglePamentCard data={data?.payment} refreshData={refreshData} />
        )}
        {error && !data && !loading && (
          <div className="page-loading-wrapper">
            <div className="no-data-wrapper">
              <BsFillMenuButtonWideFill className="no-data-icon" />

              <BasicTitle title="Something went wrong!!" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SinglePaymentDetail;

function SinglePamentCard({ data, refreshData }) {
  const [verifyPaymentLoading, setVerifyPaymentLoading] = useState(false);

  const {
    user: {
      fullname,
      grade,
      phone,
      email,
      createdAt,
      uid,
      subscription,
      schools,
      isSubscribed,
    },
    createdAt: paidAt,
    plan,
    bank,
    id,
    img,
    schoolBonusTransaction,
  } = data;
  const {
    loading,
    data: invitationLevels,
    error,
    refreshData: refreshInvitationData,
  } = useFetch(
    `/admin/api/get-invitation-upto-some-level/${uid}?paymentId=${id}`,
    "users"
  );
  const [bonusLoading, setBonusLoading] = useState(null);
  const [schoolBonusLoading, setSchoolBonusLoading] = useState(null);
  const navigator = useNavigate();
  const { logoutAdmin } = useProviderContext();
  const accessToken = getAccessToken();
  useEffect(() => {
    if (!accessToken) {
      logoutAdmin(false, refreshData).then((isLoggedOut) => {
        if (isLoggedOut) {
          navigator("/login");
        }
      });
    }
  }, [logoutAdmin, accessToken, navigator, refreshData]);

  const handleVerification = async () => {
    try {
      await axios.post("/admin/api/verify-payment", {
        paymentId: id,
        plan,
      });
      refreshData();
    } catch (err) {
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        const isLoggedOut = await logoutAdmin(false, handleVerification);
        if (isLoggedOut) {
          toast.warn("Your loggin session is expired");
          navigator("/login");
        }
        return;
      }
    }
  };
  const handleAddingBonus = async (paymentId, joinerId, inviterId, i) => {
    try {
      setBonusLoading(i);
      const resp = await axios.post("/admin/api/give-bonus-for-invitation", {
        joinerId,
        paymentId,
        inviterId,
      });
      if (resp.status === 200) {
        toast.success(resp.data.message);
        refreshInvitationData();
        refreshData();
      } else {
        throw new Error(resp.data.message ?? "Something went wrong!!");
      }
    } catch (err) {
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        const isLoggedOut = await logoutAdmin(false, () =>
          handleAddingBonus(paymentId, joinerId, inviterId, i)
        );
        if (isLoggedOut) {
          toast.warn("Your loggin session is expired");
          navigator("/login");
        }
        return;
      }
      toast.error(err?.response?.data?.message ?? "Something went wrong!!");
    } finally {
      setTimeout(() => {
        setBonusLoading(null);
      }, 500);
    }
  };
  const showTransactionDetail = async (transaction) => {
    MySwal.fire({
      title: "Transaction Details",
      html: ReactDOMServer.renderToString(
        <TransactionDetail transaction={transaction} />
      ),
      showConfirmButton: true,
    });
  };

  const handleRejectingPayment = async () => {
    try {
      await axios.post("/admin/api/reject-payment", {
        paymentId: id,
        uid,
        plan,
      });
      toast.success("Payment successfully rejected!!");
      navigator("/");
    } catch (err) {
      console.log(err);
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        const isLoggedOut = await logoutAdmin(false, handleRejectingPayment);
        if (isLoggedOut) {
          toast.warn("Your loggin session is expired");
          navigator("/login");
        }
        return;
      }
      toast.error(err?.response?.data?.message ?? "Something went wrong!!");
    }
  };

  const handleNavigation = (uid) => {
    navigator("/users/" + uid);
  };
  const titles = [
    "Direct level",
    "Second level",
    "Third level",
    "Fourth level",
  ];

  const handleSchoolBonus = async (schoolCode, uid) => {
    try {
      setSchoolBonusLoading(true);
      const resp = await axios.get(
        `/admin/api//add-school-bonus/${schoolCode}/${uid}`
      );
      if (resp.status === 200) {
        toast.success(resp.data.message);
        refreshInvitationData();
        refreshData();
      } else {
        throw new Error(resp.data.message ?? "Something went wrong!!");
      }
    } catch (err) {
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        const isLoggedOut = await logoutAdmin(false, () =>
          handleSchoolBonus(schoolCode, uid)
        );
        if (isLoggedOut) {
          toast.warn("Your loggin session is expired");
          navigator("/login");
        }
        return;
      }
      toast.error(err?.response?.data?.message ?? "Something went wrong!!");
    } finally {
      setSchoolBonusLoading(null);
    }
  };

  const showChapaTransactionDetail = async (chapaTransaction) => {
    MySwal.fire({
      title: "Chapa Transaction Details",
      html: ReactDOMServer.renderToString(
        <ChapaTransactionDetail chapaTransaction={chapaTransaction} />
      ),
      showConfirmButton: true,
    });
  };
  const handleVerifyingChapaPayment = async (autoTxRef) => {
    try {
      setVerifyPaymentLoading(true);
      const response = await axios.get(
        "/admin/api/verify-chapa-payment/" + autoTxRef
      );
      console.log(response);
      showChapaTransactionDetail(response.data.chapaTransaction);
      refreshData();
    } catch (err) {
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        const isLoggedOut = await logoutAdmin(false, () =>
          handleVerifyingChapaPayment(autoTxRef)
        );
        if (isLoggedOut) {
          toast.warn("Your loggin session is expired");
          navigator("/login");
        }
        setVerifyPaymentLoading(false);
        return;
      } else {
        console.log(err);
        toast.error(err?.response?.data?.message ?? "Something went wrong.");
      }
    } finally {
      setVerifyPaymentLoading(false);
    }
  };

  return (
    <div className="single-payment-card">
      <div className="single-payment-card-profile-wrapper">
        <div className="profile-avatar-wrapper">
          <AiOutlineUser className="profile-avatar" />
          <div className="profile-fullname">{fullname}</div>
        </div>
        <div className="profile-grade">{grade}</div>
      </div>

      <div className="single-payment-data">
        <BasicTitle title={"Payment data"} />
        <Tile
          title={"paid at"}
          info={moment(paidAt).fromNow()}
          icon={<AiOutlineFieldTime className="tile-icon" />}
        />
        <Tile
          title={"Plan"}
          info={
            plan === "grade12n"
              ? "Grade 12 natural"
              : plan === "grade12s"
              ? "Grade 12 Social"
              : plan === "grade8"
              ? "Grade 8"
              : plan
          }
          icon={<AiFillBook className="tile-icon" />}
        />
        <Tile
          title={"Bank"}
          info={bank}
          icon={<AiTwotoneHome className="tile-icon" />}
        />

        {img ? (
          <div className="payment-image">
            {" "}
            <BasicTitle title={"Screenshot"} />{" "}
            <div className="payment-image-wrapper">
              <img
                src={`${APP_SERVER}/admin/payemnt-verification/${img}?accessToken=${getAccessToken()}`}
                alt="payment screenshot"
                className="payment-screenshot"
                loading="lazy"
              />
            </div>
          </div>
        ) : (
          <>
            <BasicTitle title={"Chapa transaction detail:"} />{" "}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                margin: "12px 12px",
              }}
            >
              <div>
                {" "}
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Tx. Ref:{" "}
                </span>
                {data.autoTxRef}
              </div>
              <button
                className="verify-button"
                onClick={() => {
                  handleVerifyingChapaPayment(data.autoTxRef);
                }}
                style={{
                  height: "40px",
                  minWidth: "120px",
                  fontSize: ".8rem",
                }}
              >
                {verifyPaymentLoading ? (
                  <div
                    style={{
                      height: "12px",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Loader width={"60"} height={12} color={"white"} />
                  </div>
                ) : (
                  "View Transaction"
                )}
              </button>
            </div>
          </>
        )}

        <div className="payment-verify-wrapper">
          {subscription != null && subscription[plan] ? (
            <button
              className="verified-button"
              onClick={() => handleNavigation(uid)}
            >
              <span className="verify-button-text"> See user </span>
              <AiOutlinePushpin className="verify-button-icon" />
            </button>
          ) : (
            <>
              <button className="verify-button" onClick={handleVerification}>
                <span className="verify-button-text">Verify account</span>
                <AiFillUnlock className="verify-button-icon" />
              </button>
              <button
                className="verify-button payment-delete-button"
                style={{ background: "crimson" }}
                onClick={() => handleRejectingPayment()}
              >
                <span className="verify-button-text">Reject Payment</span>
                <AiOutlineDeleteColumn className="verify-button-icon" />
              </button>
            </>
          )}
        </div>
        {schoolBonusTransaction ? (
          <div
            style={{
              margin: "10px 2px",
            }}
          >
            <BasicTitle title={"School bonus Transaction"} />
            <ListTile
              leading={`+${schoolBonusTransaction.amount}`}
              title={`Bank No: ${schoolBonusTransaction.bankNo}`}
              subtitle={`+${schoolBonusTransaction.amount} ETB`}
              color={"orange"}
              info={schoolBonusTransaction?.type?.toUpperCase()}
              trailing={
                <Link
                  to={`/schools/${schoolBonusTransaction.schoolCode}`}
                  style={{
                    textDecoration: "underline",
                    display: "block",
                    textAlign: "right",
                  }}
                >
                  See School and all Transaction
                </Link>
              }
            />
          </div>
        ) : schools?.allowPromotion ? (
          <div
            style={{
              margin: "10px 2px",
              background: "rgba(0,0,0,0.075)",
              padding: "8px 12px",
            }}
          >
            <BasicTitle title={"Give School bonus"} />
            <div
              className="payment-verify-wrapper"
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                margin: "15px 2px",
              }}
            >
              <div>
                <BasicTitle
                  title={` SCHOOL: ${schools.schoolName?.toUpperCase()}`}
                />
                <Link
                  to={`/schools/${schools.schoolCode}`}
                  style={{
                    display: "block",
                    textDecoration: "underline",
                  }}
                >
                  See School
                </Link>
              </div>
              <button
                className="verified-button"
                onClick={() => handleSchoolBonus(schools.schoolCode, uid)}
              >
                <span className="verify-button-text">
                  {" "}
                  {schoolBonusLoading ? "Adding" : "Add bonus to school"}
                </span>
                <AiFillPlusCircle className="verify-button-icon" />
              </button>
            </div>
          </div>
        ) : schools ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <BasicTitle
              title={
                "User has school but did not have promotion. (School Name: " +
                schools.schoolName +
                ")"
              }
            />
            <Link
              to={`/schools/${schools.schoolCode}`}
              style={{
                display: "block",
                textDecoration: "underline",
              }}
            >
              See School
            </Link>
          </div>
        ) : (
          <BasicTitle title={"User did not has school."} />
        )}
        <div
          style={{
            margin: "10px 2px",
          }}
        >
          {invitationLevels != null && invitationLevels?.users?.length === 0 ? (
            <BasicTitle title={"User did not invited by users."} />
          ) : (
            <BasicTitle title={"Users invitation level"} />
          )}
          {loading && !invitationLevels && (
            <div className="small-page-loading-wrapper">
              <Loader color={"crimson"} />
            </div>
          )}
          {invitationLevels?.users?.length === 0 && (
            <div style={{ textAlign: "center" }}>No user invited him</div>
          )}
          {invitationLevels != null &&
            invitationLevels !== 0 &&
            invitationLevels?.users?.map((user, i) => {
              const color = chooseLevelColor(i + 1);

              return (
                <div
                  style={{
                    margin: "10px 2px",
                  }}
                  key={user.uid}
                >
                  <ListTile
                    leading={i + 1}
                    title={user.fullname}
                    subtitle={user.phone}
                    color={color}
                    info={titles[i]}
                    trailing={
                      !user?.wallet?.transaction?.length ? (
                        <button
                          className="give-bonus-button"
                          style={{
                            background: color,
                            height: "38px",
                            width: "100px",
                          }}
                          onClick={() =>
                            handleAddingBonus(id, uid, user.uid, i)
                          }
                        >
                          {bonusLoading === i ? (
                            <Loader width={"60"} height={6} color={"white"} />
                          ) : (
                            "Give Bonus"
                          )}
                        </button>
                      ) : (
                        <button
                          className="give-bonus-button"
                          onClick={() => {
                            showTransactionDetail(user.wallet.transaction[0]);
                          }}
                        >
                          See Bonus Transaction
                        </button>
                      )
                    }
                  />
                </div>
              );
            })}

          {error && !data && (
            <button className="retry-button" onClick={refreshInvitationData}>
              {" "}
              <AiOutlineReload
                className="user-detail-refresh-icon"
                style={{
                  margin: "0 4px 0 0",
                  width: "16px",
                }}
              />{" "}
              Retry
            </button>
          )}
          <div
            style={{
              marginTop: "15px",
            }}
          >
            <div>Sender</div>
            <ListTile
              leading={fullname[0]}
              title={fullname}
              subtitle={phone}
              color="crimson"
              trailing={isSubscribed ? "Subscribed" : "Not Subscribed"}
            />
          </div>
        </div>
      </div>

      <div className="profile-info-table">
        <BasicTitle title={"User detail"} />
        <Tile
          title={"phone"}
          info={phone}
          icon={<AiFillPhone className="tile-icon" />}
        />
        <Tile
          title={"Email"}
          info={email}
          icon={<AiOutlineMail className="tile-icon" />}
        />
        <Tile
          title={"Acc. Created At"}
          info={moment(createdAt).fromNow()}
          icon={<AiOutlineFieldTime className="tile-icon" />}
        />
      </div>
    </div>
  );
}

function Tile({ title, info, icon }) {
  return (
    <div className="profile-info-tile">
      <div>
        {icon}
        <span className="tile-title">{title}:</span>
      </div>
      <span className="tile-data"> {info} </span>
    </div>
  );
}

function chooseLevelColor(level) {
  switch (level) {
    case 1:
      return "#4CAF50"; // Cool Green
    case 2:
      return "#FFA726"; // Cool Orange
    case 3:
      return "#FF5252"; // Cool Red
    case 4:
      return "#FFB74D"; // Lighter Cool Orange
    default:
      return "#E91E63"; // Cool Pink
  }
}
