import React from "react";
import "./questioncard.css";
import { parseText } from "./utils/utils";
import { APP_SERVER } from "../../../../global";
import { getAccessToken } from "../../../util/accessToken";

const QuestionCard = ({ questionData, exam, isFullScreen }) => {
  const { no, question, choices, answer, explanation, unit, grade } =
    questionData;
  const handleImageError = async (e) => {};
  return (
    <div
      className="question-card"
      style={{
        color: isFullScreen ? "#ddd" : "black",
      }}
    >
      <h2 className="question-tag">{no}</h2>
      {questionData.questionImg &&
        questionData?.position?.question === "top" && (
          <div>
            {questionData?.questionImg?.title && (
              <h4>{parseText(questionData?.questionImg?.title)}</h4>
            )}
            {questionData?.questionImg?.image && (
              <img
                src={
                  APP_SERVER +
                  `/admin/exam-image/${exam.eid}/${
                    questionData?.questionImg?.image
                  }?accessToken=${getAccessToken()}`
                }
                alt={""}
                onError={handleImageError}
                className="image-constraints"
              />
            )}
            {questionData?.questionImg?.subtitle && (
              <h4>{parseText(questionData?.questionImg?.subtitle)}</h4>
            )}
          </div>
        )}
      <p>{parseText(question)}</p>

      {questionData.questionImg &&
        questionData?.position?.question !== "top" && (
          <div>
            {questionData?.questionImg?.title && (
              <h4>{parseText(questionData?.questionImg?.title)}</h4>
            )}
            {questionData?.questionImg?.image && (
              <img
                src={
                  APP_SERVER +
                  `/admin/exam-image/${exam.eid}/${
                    questionData?.questionImg?.image
                  }?accessToken=${getAccessToken()}`
                }
                alt={""}
                onError={handleImageError}
                className="image-constraints"
              />
            )}
            {questionData?.questionImg?.subtitle && (
              <h4>{parseText(questionData?.questionImg?.subtitle)}</h4>
            )}
          </div>
        )}

      <ul>
        {questionData.choicesImg
          ? Object.keys(questionData.choicesImg).map((choiceImg) => {
              return (
                <div key={choiceImg}>
                  {questionData?.choicesImg[choiceImg]?.title && (
                    <div>{questionData?.choicesImg[choiceImg]?.title}</div>
                  )}
                  <div className="choice-img-wrapper">
                    <div className="choice-img-wrapper-layer"></div>
                    <strong>{choiceImg.toUpperCase()}:</strong>
                    <img
                      src={
                        APP_SERVER +
                        `/admin/exam-image/${exam.eid}/${
                          questionData?.choicesImg[choiceImg]?.image
                        }?accessToken=${getAccessToken()}`
                      }
                      alt=""
                      className="image-constraints"
                    />
                  </div>
                  {questionData?.choicesImg[choiceImg]?.subtitle && (
                    <div>{questionData?.choicesImg[choiceImg]?.subtitle}</div>
                  )}
                </div>
              );
            })
          : Object.keys(choices).map((key) => (
              <li
                key={key}
                className={`normal-choice ${
                  answer?.toLowerCase() === key?.toLowerCase()
                    ? " choice-answer"
                    : ""
                }`}
              >
                <strong>{key.toUpperCase()}:</strong> {parseText(choices[key])}
              </li>
            ))}
      </ul>
      <div className="explanation-holder">
        <strong>Explanation:</strong> <br />
        {questionData.explanationImg &&
          questionData?.position?.explanation === "top" && (
            <div>
              {questionData?.explanationImg?.title && (
                <h4>{parseText(questionData?.explanationImg?.title)}</h4>
              )}
              {questionData?.explanationImg?.image && (
                <img
                  src={
                    APP_SERVER +
                    `/admin/exam-image/${exam.eid}/${
                      questionData?.explanationImg?.image
                    }?accessToken=${getAccessToken()}`
                  }
                  alt={""}
                  onError={handleImageError}
                  className="image-constraints"
                />
              )}
              {questionData?.explanationImg?.subtitle && (
                <h4>{parseText(questionData?.explanationImg?.subtitle)}</h4>
              )}
            </div>
          )}
        {parseText(explanation)}
        {questionData.explanationImg &&
          questionData?.position?.explanation !== "top" && (
            <div>
              {questionData?.explanationImg?.title && (
                <h4>{parseText(questionData?.explanationImg?.title)}</h4>
              )}
              {questionData?.explanationImg?.image && (
                <img
                  src={
                    APP_SERVER +
                    `/admin/exam-image/${exam.eid}/${
                      questionData?.explanationImg?.image
                    }?accessToken=${getAccessToken()}`
                  }
                  alt={""}
                  onError={handleImageError}
                  className="image-constraints"
                />
              )}
              {questionData?.explanationImg?.subtitle && (
                <h4>{parseText(questionData?.explanationImg?.subtitle)}</h4>
              )}
            </div>
          )}
      </div>
      <div className="question-info">
        <p>
          <strong>Grade:</strong> {grade}
        </p>
        <p>
          <strong>Unit:</strong> {unit}
        </p>
      </div>
    </div>
  );
};

export default QuestionCard;
