import React, { useState } from "react";

import { AiOutlineMail, AiOutlineReload } from "react-icons/ai";
import BasicTitle from "../../common/BasicTitle";

import "./emailUsers.css";
import { toast } from "react-toastify";
import axios from "axios";
function EmailUsers(props) {
  const [who, setWho] = useState("all");

  const [title, setTitle] = useState("");
  const [exams, setExams] = useState("");
  const [message, setMessage] = useState("");

  const handleEmailing = async (e) => {
    e.preventDefault();
    console.log(message.length, title.length);
    if (title.length < 5 || message.length < 20) {
      toast.warn("You are missing the point.");
      return;
    }
    try {
      const resp = await axios.post("/admin/api/email-users", {
        title,
        exams,
        message,
        who,
      });
      toast.success(resp.data.message);
      setTitle("");
      setExams("");
      setMessage("");
    } catch (err) {
      toast.error(err?.message ?? "Something went error!!");
    }
  };

  return (
    <div className="user-detail-page">
      <div className="user-detail-content-wrapper">
        <div className="user-detail-header">
          <h1 className="common-pages-title"> Email Users </h1>
          <div className="user-detail-refresh-wrapper">
            <button
              className="user-detail-refresh-icon-button"
              onClick={() => {}}
            >
              Refresh
              <AiOutlineReload className="user-detail-refresh-icon" />
            </button>
          </div>
        </div>

        <div>
          <br />
          <div>
            <div className={"email-filter-option-boxes"}>
              <button
                className={
                  who === "all"
                    ? "email-filter-option-selected"
                    : "email-filter-option"
                }
                onClick={() => setWho("all")}
              >
                <label className="email-filter-label">ALL</label>
                <input
                  className="email-filter-box"
                  name="who"
                  value={"all"}
                  type="radio"
                  checked={who === "all"}
                  onChange={(e) => setWho(e.target.value)}
                />
              </button>
              <button
                className={
                  who === "grade8"
                    ? "email-filter-option-selected"
                    : "email-filter-option"
                }
                onClick={() => setWho("grade8")}
              >
                <label className="email-filter-label">Grade 8</label>
                <input
                  className="email-filter-box"
                  name="who"
                  value={"grade8"}
                  type="radio"
                  checked={who === "grade8"}
                  onChange={(e) => setWho(e.target.value)}
                />
              </button>
              <button
                className={
                  who === "grade12s"
                    ? "email-filter-option-selected"
                    : "email-filter-option"
                }
                onClick={() => setWho("grade12s")}
              >
                <label className="email-filter-label">Grade 12 Social</label>
                <input
                  className="email-filter-box"
                  name="who"
                  value={"grade12s"}
                  checked={who === "grade12s"}
                  type="radio"
                  onChange={(e) => setWho(e.target.value)}
                />
              </button>
              <button
                className={
                  who === "grade12n"
                    ? "email-filter-option-selected"
                    : "email-filter-option"
                }
                onClick={() => setWho("grade12n")}
              >
                <label className="email-filter-label">Grade 12 Natural </label>
                <input
                  className="email-filter-box"
                  name="who"
                  value={"grade12n"}
                  checked={who === "grade12n"}
                  type="radio"
                  onChange={(e) => setWho(e.target.value)}
                />
              </button>
            </div>
          </div>

          <div className="emailing-form">
            <BasicTitle title={"Title"} />
            <input
              type="text"
              className="email-title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <BasicTitle title={"Released Subjects"} />
            <input
              type="text"
              className="exams-released"
              value={exams}
              onChange={(e) => setExams(e.target.value)}
            />
            <BasicTitle title={"Message"} />
            <textarea
              className="email-textarea"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>

            <button className="email-the-message" onClick={handleEmailing}>
              Email <AiOutlineMail className="email-icon" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailUsers;
