import React from "react";
import {
  AiOutlineAim,
  AiOutlineBook,
  AiOutlineCalendar,
  AiOutlineDashboard,
  AiOutlineDatabase,
  AiOutlineDownload,
} from "react-icons/ai";
import BasicTitle from "../../common/BasicTitle";
import { useNavigate } from "react-router-dom";
import ExamStaticsBox from "./ExamStaticsBox";
import GradeStaticsBox from "./GradeStaticsBox";

function GradeDetail({ stat, grade, gradeTitle }) {
  const navigator = useNavigate();
  const assetsContext = require.context("../../../assets/svg/subject/", true);
  const gradeData =
    grade === "8"
      ? stat?.flatGrade8
      : grade === "12n"
      ? stat?.flatGrade12N
      : grade === "12s"
      ? stat?.flatGrade12S
      : stat?.flatGrade10;
  // console.log(gradeData);
  return (
    <div className="one-grade-whole">
      <BasicTitle
        title={`Grade ${gradeTitle}`}
        className="primary-title big-title"
      />

      <div className="statics-wrapper ">
        <GradeStaticsBox
          className="tertiary-title"
          title={`Grade ${gradeTitle} exam statics`}
          main={gradeData?.main?.count}
          model={gradeData?.model?.count}
          others={gradeData?.others?.count}
          mainDownload={gradeData?.main?.download}
          modelDownload={gradeData?.model?.download}
          othersDownload={gradeData?.others?.download}
        />
        <ExamStaticsBox
          className="primary-title"
          title={`Weekly grade ${gradeTitle} added Exams`}
          main={gradeData?.main?.monthly}
          model={gradeData?.model?.monthly}
          others={gradeData?.others?.monthly}
        />
        <ExamStaticsBox
          className="secondary-title"
          title={`Monthly grade ${gradeTitle} added Exams`}
          main={gradeData?.main?.monthly}
          model={gradeData?.model?.monthly}
          others={gradeData?.others?.monthly}
        />
      </div>

      <BasicTitle
        title={`Grade ${gradeTitle} Subject Detail`}
        className="tertiary-title subject-grade-title"
      />
      <div className="statics-wrapper">
        {gradeData?.subjectInformation?.map((subjectData) => {
          return (
            <div
              key={subjectData?.subject}
              className={`subject-card  prefix-${subjectData?.subject}`}
              onClick={() =>
                navigator("/subject-exams-detail/" + subjectData?.subject, {
                  state: {
                    ...subjectData,
                    list: gradeData?.flat?.filter(
                      (exam) => exam.subject === subjectData?.subject
                    ),
                    gradeTitle: gradeTitle,
                  },
                })
              }
            >
              {" "}
              <img
                src={assetsContext(`./${subjectData.subject}.svg`)}
                alt={subjectData.subject}
                className={`subject-svg`}
              />
              <div className="subject-title"> {subjectData?.subject} </div>
              <div className="subject-sub-title">Count</div>
              <SubjectTile
                title={"Main"}
                icon={<AiOutlineBook className="subject-tile-icon" />}
                data={subjectData?.main?.count}
              />
              <SubjectTile
                title={"Model"}
                icon={<AiOutlineDashboard className="subject-tile-icon" />}
                data={subjectData?.model?.count}
              />
              <SubjectTile
                title={"Others"}
                icon={<AiOutlineAim className="subject-tile-icon" />}
                data={subjectData?.others?.count}
              />
              <SubjectTile
                title={"Total"}
                icon={<AiOutlineDatabase className="subject-tile-icon" />}
                data={subjectData?.count}
              />
              <div className="subject-sub-title">Specific Updates</div>
              <SubjectTile
                title={"Main Monthly"}
                icon={<AiOutlineBook className="subject-tile-icon" />}
                data={subjectData?.main?.monthly}
              />
              <SubjectTile
                title={"Model Monthly"}
                icon={<AiOutlineDashboard className="subject-tile-icon" />}
                data={subjectData?.model?.monthly}
              />
              <SubjectTile
                title={"Others Monthly"}
                icon={<AiOutlineAim className="subject-tile-icon" />}
                data={subjectData?.others?.monthly}
              />
              <div className="subject-sub-title">Updates</div>
              <SubjectTile
                title={"Weekly"}
                icon={<AiOutlineCalendar className="subject-tile-icon" />}
                data={subjectData?.weekly}
              />
              <SubjectTile
                title={"Monthly"}
                icon={<AiOutlineCalendar className="subject-tile-icon" />}
                data={subjectData?.monthly}
              />
              <div className="subject-sub-title">Download</div>
              <SubjectTile
                title={"Main"}
                icon={<AiOutlineBook className="subject-tile-icon" />}
                data={subjectData?.main?.download}
              />
              <SubjectTile
                title={"Model"}
                icon={<AiOutlineDashboard className="subject-tile-icon" />}
                data={subjectData?.model?.download}
              />
              <SubjectTile
                title={"Others"}
                icon={<AiOutlineAim className="subject-tile-icon" />}
                data={subjectData?.others?.download}
              />
              <SubjectTile
                title={"Total Download"}
                icon={<AiOutlineDownload className="subject-tile-icon" />}
                data={subjectData?.totalDownload}
              />
              <div className="subject-grade-tag"> Grade {gradeTitle} </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default GradeDetail;

function SubjectTile({ data, title, icon }) {
  return (
    <div className="subject-tile">
      <div>
        {icon}
        {title}
      </div>
      <span className="subject-tile-data">{data}</span>
    </div>
  );
}
