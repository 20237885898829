import React, { useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logo/logo.png";
import "./login.css";
import axios from "axios";
import { toast } from "react-toastify";

import { useProviderContext } from "../provider/appProvider";
import Loader from "../common/Loader";

function AdminLogin() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const {
    state: { admin },
    logAdmin,
  } = useProviderContext();

  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    if (username.length < 5 || password.length < 6) {
      toast.error("Please fill valid credentials!!", {
        autoClose: 2000,
        toastId: "id",
      });
      return;
    }
    try {
      setIsLoading(true);
      const resp = await axios.post("/admin/api/login", {
        username,
        password,
      });
      if (!resp) {
        toast.error("Something went wrong!!", {
          autoClose: 2000,
          toastId: "id",
        });
      }
      const admin = resp.data.admin;
      const accessToken = resp.data.accessToken;
      if (!admin && !accessToken) {
        toast.error("Something went wrong!!", {
          autoClose: 2000,
          toastId: "id",
        });
      }
      setIsLoading(false);
      logAdmin(admin, accessToken);
      console.log(queryParams.get("next"), queryParams.get("nexts"));
      navigator(queryParams.get("next") ?? "/");
    } catch (err) {
      setIsLoading(false);
      if (toast.isActive()) {
        toast.dismiss("id");
      } else {
        toast.error(err?.response?.data?.message ?? "Something went wrong!!", {
          autoClose: 2000,
          toastId: "id",
        });
      }
    }
  };
  const navigator = useNavigate();
  if (admin) {
    return <Navigate to="/" replace />;
  }
  return (
    <div className="admin-login-wrapper">
      <form className="admin-login">
        <div className="admin-login-topic">
          <img src={logo} className="admin-logo" alt="" />
          <h3 className="roboto admin-login-title">Freedom Learn</h3>
        </div>
        <div className="admin-login-form">
          <div className="admin-form-block"></div>
          <div className="admin-form-block">
            <p className="ubuntu admin-form-label">UserName</p>
            <input
              type="text"
              className="admin-form-input"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>

          <div className="admin-form-block">
            <p className="ubuntu admin-form-label">Password</p>
            <input
              type="password"
              className="admin-form-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="admin-form-block"></div>
          <div className="admin-form-block">
            <button
              className="admin-form-button roboto"
              type="submit"
              onClick={handleLogin}
              disabled={isLoading}
            >
              {isLoading ? (
                <Loader width={80} height={30} color={"white"} />
              ) : (
                "LOGIN"
              )}
            </button>
          </div>
          <div className="admin-form-block">
            <p className="admin-form-quote">
              {/*<Link  to = "/admin/forget-password/hashed" className = "forget-link">Forget Password? </Link>*/}
            </p>
          </div>
          <div className="admin-form-block">
            <p className="admin-form-quote">
              &copy; Freedom-learn developed by{" "}
              <a href="https://t.me/abenezer_tigistu" className="abux-link">
                Abux
              </a>
            </p>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AdminLogin;
