import React from "react";
import "./common.css";
import { InfinitySpin } from "react-loader-spinner";

function Loader({ width, height, color, containerStyle }) {
  return (
    <div className="" width={width} height={height} style={containerStyle}>
      <div
        style={{
          transform: "translate(-45%)",
        }}
      >
        <InfinitySpin
          // type="TailSpin"
          color={color ?? "white"}
          width={width}
          height={height}

          // width="80px"
        />
      </div>
    </div>
  );
}

export default Loader;
