import parse, { domToReact } from "html-react-parser";
import "katex/dist/katex.min.css";
import { BlockMath, InlineMath } from "react-katex";

const replaceSpecialChars = (text) => {
  if (!text) return "";
  return text
    .replace(/\\n|\n/g, "<br/>") // Replace line breaks with <br/>
    .replace(/<dot\/>/g, "•"); // Replace <dot/> with a bullet point
};

const renderLatex = (latex, displayMode = false) => {
  return displayMode ? <BlockMath math={latex} /> : <InlineMath math={latex} />;
};

export const parseText = (text) => {
  const formattedText = replaceSpecialChars(text);
  return parse(formattedText, {
    replace: (domNode) => {
      if (domNode.name === "latex") {
        // Convert children to plain text if necessary
        const latexContent = domNode.children
          .map((child) => (typeof child === 'string' ? child : child.data))
          .join("");
        const displayMode = domNode.attribs.display === "block";
        return renderLatex(latexContent, displayMode);
      }
    },
  });
};
 