import React, { useState } from "react";
import {
  AiOutlineBook,
  AiOutlineBranches,
  AiOutlineReload,
} from "react-icons/ai";
import InfiniteScroll from "../../infiniteScroll/InfiniteScroll";
import { BsAlarm, BsFillMenuButtonWideFill } from "react-icons/bs";
import BasicTitle from "../../common/BasicTitle";
import { useProviderContext } from "../../provider/appProvider";
import moment from "moment";
import { Link } from "react-router-dom";

const ExamCook = () => {
  const {
    state: { examSearch: search },
    searchExam: setSearch,
  } = useProviderContext();
  const [url, setUrl] = useState("/admin/api/exams");
  const refreshData = () => {
    setUrl((url) => (url.endsWith("/") ? url.slice(0, -1) : url + "/"));
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  // const handleVcfDownload = async (users) => {
  //   // downloadVCF(users);
  // };

  return (
    <div className="user-detail-page">
      <div className="user-detail-content-wrapper">
        <div className="user-detail-header">
          <h1 className="common-pages-title"> Exam Cook kitchen </h1>
          <div className="user-detail-refresh-wrapper">
            <button
              className="user-detail-refresh-icon-button"
              onClick={refreshData}
            >
              Refresh
              <AiOutlineReload className="user-detail-refresh-icon" />
            </button>
          </div>
        </div>
        <form className="search-tool-wrapper">
          <input
            type="text"
            value={search}
            onChange={(e) => handleSearch(e)}
            className="user-search-input"
            placeholder="search"
          />
        </form>

        <InfiniteScroll url={url} query={search} dataKey={"exams"}>
          {function (exams, lastElementRef) {
            return exams.length === 0 ? (
              <div className="page-loading-wrapper">
                <div className="no-data-wrapper">
                  <BsFillMenuButtonWideFill className="no-data-icon" />

                  <BasicTitle title="There is no Exams in the docket" />
                </div>
              </div>
            ) : (
              <>
                {exams.map((exam, index) => (
                  <ExamDetailCard
                    key={exam.eid}
                    exam={exam}
                    search={search}
                    lastElementRef={
                      index === exams.length - 1 ? lastElementRef : null
                    }
                  />
                ))}
              </>
            );
          }}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default ExamCook;

function ExamDetailCard({ exam, search, lastElementRef }) {
  const structures = exam.structure;
  const firstStructure = structures ? structures[0] : null;
  const timing = moment(exam.createdAt).fromNow();
  return (
    <Link
      className="user-detail-card"
      to={"/exam-cook/" + exam.eid}
      ref={lastElementRef}
    >
      <div className="upper-user-card">
        <h5 className="user-card-name">
          <AiOutlineBook className="user-card-name-icon" />
          <SearchGoogle from={firstStructure?.title} search={search} />
          <span className="user-card-grade">
            {" "}
            TYPE:{" "}
            <SearchGoogle
              from={firstStructure.type}
              search={search}
              greenMark={true}
            />
            <AiOutlineBranches className="user-card-grade-icon" />{" "}
          </span>
        </h5>
        <div className="user-card-time">
          {" "}
          <BsAlarm className="user-card-time-icon" /> {timing}
        </div>
      </div>
      <div className="bottom-user-card">
        <div className="user-card-phone">
          <SearchGoogle from={exam.grade} search={search} />
        </div>

        <div className="user-card-email">
          <SearchGoogle from={exam.subject} search={search} />{" "}
        </div>
      </div>
    </Link>
  );
}

function SearchGoogle({ from, search, greenMark }) {
  return (
    <>
      {search.length
        ? from
            .toLowerCase()
            .split(search.toLowerCase())
            .join(`|${search}|`)
            .split("|")
            .map((term, i) => {
              return (
                <span
                  className={
                    search.toLowerCase() === term.toLowerCase()
                      ? greenMark
                        ? "search-term-mark-green"
                        : "search-term-mark"
                      : ""
                  }
                  key={term + "-" + i}
                >
                  {term}
                </span>
              );
            })
        : from}
    </>
  );
}
