import React from "react";
import "./subjectExamDetail.css";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  AiOutlineBook,
  AiOutlineCalendar,
  AiOutlineDownload,
} from "react-icons/ai";
import moment from "moment";
function SubjectExamDetail(props) {
  const { key: subject } = useParams();
  const { state: data } = useLocation();
  return (
    <div className="payment-detail-page">
      <div className="payment-detail-content-wrapper">
        <div className="user-detail-header">
          <h1 className="common-pages-title"> Exam statistics </h1>
          <div className="user-detail-refresh-wrapper"></div>
        </div>
        <div className={`exam-book-header prefix-` + subject}>
          <AiOutlineBook className="exam-book-icon" />
          <div className="exam-book-title subject-sub-title">
            Grade {data?.gradeTitle} Subject: {subject?.toUpperCase()}
          </div>
        </div>
        {data?.list &&
          data?.list
            ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            ?.map((exam) => (
              <Link
                style={{
                  display: "block",
                }}
                className={`exam-book-card prefix-${subject}`}
                key={exam?.id}
                to={"/exam-cook/" + exam.id}
              >
                <div className="exam-book-card-header">
                  <div className="exam-book-card-left">
                    <AiOutlineBook className="exam-book-card-icon" />
                    <div className="exam-book-title subject-sub-title">
                      {exam?.title}
                    </div>
                  </div>
                  <div className="exam-book-download-header">
                    <AiOutlineDownload className="download-icon" />
                    {exam?.examFromDB?.download}
                  </div>
                </div>
                <div className="exam-book-info">
                  <SubjectTile
                    title={"Created At"}
                    icon={<AiOutlineCalendar className="subject-tile-icon" />}
                    data={moment(exam?.examFromDB?.createdAt).fromNow()}
                  />
                  <SubjectTile
                    title={"Year"}
                    icon={<AiOutlineCalendar className="subject-tile-icon" />}
                    data={exam?.year}
                  />
                </div>
                <div className="exam-card-top">{exam?.type}</div>
                {exam?.commingSoon && (
                  <div className="exam-card-top-right"> Comming soon </div>
                )}
              </Link>
            ))}
      </div>
    </div>
  );
}

export default SubjectExamDetail;

function SubjectTile({ data, title, icon }) {
  return (
    <div className="subject-tile">
      <div>
        {icon}
        {title}
      </div>
      <span className="subject-tile-data">{data}</span>
    </div>
  );
}
