import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useProviderContext } from "../provider/appProvider";

function ProtectedRoute(props) {
  const location = useLocation();
  const {
    state: { admin },
  } = useProviderContext();

  return admin ? (
    <Outlet />
  ) : (
    <Navigate to={`/login?next=${location.pathname}`} replace />
  );
}

export default ProtectedRoute;
