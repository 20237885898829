import { BsFillMenuButtonWideFill } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Loader from "../../common/Loader";
import BasicTitle from "../../common/BasicTitle";
import ReactDOMServer from "react-dom/server";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  AiFillInfoCircle,
  AiOutlineDeleteColumn,
  AiOutlineFieldTime,
  AiOutlineHome,
  AiOutlineTransaction,
  AiOutlineUser,
} from "react-icons/ai";
import moment from "moment";
import axios from "axios";
import { useProviderContext } from "../../provider/appProvider";
import { toast } from "react-toastify";
import ChapaTransactionDetail from "./ChapaTransactionDetail.jsx";
import { useState } from "react";
import TransactionDetail from "../SinglePaymentDetail/TransactionDetail.jsx";

const MySwal = withReactContent(Swal);

function SingleWithdrawalTransactionDetail(props) {
  const { transactionId } = useParams();
  const { data, loading, error, refreshData } = useFetch(
    "/admin/api/withdrawal-transaction/" + transactionId
  );

  return (
    <div className="payment-detail-page">
      <div className="payment-detail-content-wrapper">
        {loading && (
          <div className="page-loading-wrapper">
            <div className="page-loading-wrapper">
              <Loader
                width={80}
                height={40}
                color={"#7175D6"}
                containerStyle={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            </div>
          </div>
        )}
        {data && (
          <SingleWithdrawalTransactionCard
            data={data?.transaction}
            refreshData={refreshData}
          />
        )}
        {error && !data && (
          <div className="page-loading-wrapper">
            <div className="no-data-wrapper">
              <BsFillMenuButtonWideFill className="no-data-icon" />

              <BasicTitle title={"Something went wrong!!"} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SingleWithdrawalTransactionDetail;

function SingleWithdrawalTransactionCard({ data: transaction, refreshData }) {
  const [transferLoading, setTransferLoading] = useState(false);
  const [actionedTransferLoading, setActionedTransferLoading] = useState(false);
  const [rejectWithdrawLoading, setRejectWithdrawLoading] = useState(false);
  const [rejectionReason, setRejectionReason] = useState(
    "We regret to inform you that your withdrawal request has been rejected due to insufficient funds in the account. \n"
  );
  const { logoutAdmin } = useProviderContext();
  const showTransactionDetail = async (transaction, chapaTransaction) => {
    MySwal.fire({
      title: "Transaction Details",
      html: ReactDOMServer.renderToString(
        <ChapaTransactionDetail
          transaction={transaction}
          chapaTransaction={chapaTransaction}
        />
      ),
      showConfirmButton: true,
    });
  };
  const handleTransferingMoneyToUser = async (transactionId) => {
    if (transferLoading) {
      return;
    }
    setTransferLoading(true);
    try {
      const response = await axios.get(
        "/admin/api/apply-withdraw-request/" + transactionId
      );
      refreshData();

      toast.success(
        response?.data?.message ?? "Transferring request is successful."
      );
    } catch (err) {
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        const isLoggedOut = await logoutAdmin(false, () =>
          handleTransferingMoneyToUser(transactionId)
        );
        if (isLoggedOut) {
          toast.warn("Your loggin session is expired");
          navigator("/login");
        }
        setTransferLoading(false);
        return;
      } else {
        toast.error(err?.response?.data?.message ?? "Something went wrong.");
      }
    } finally {
      setTransferLoading(false);
    }
  };
  const handleActionedTransaction = async (transaction) => {
    const transactionId = transaction.transactionId;
    if (actionedTransferLoading) {
      return;
    }
    try {
      setActionedTransferLoading(true);
      const response = await axios.get(
        "/admin/api/check-onorder-withdraw-request/" + transactionId
      );

      if (
        transaction.status === "onorder" &&
        response?.data?.transaction?.status === "success"
      ) {
        toast.success(
          response?.data?.message ?? "Transferring request is successful."
        );
      }
      console.log(response.data);
      showTransactionDetail(transaction, response?.data?.chapaTransaction);
      refreshData();
    } catch (err) {
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        const isLoggedOut = await logoutAdmin(false, () =>
          handleActionedTransaction(transaction)
        );
        if (isLoggedOut) {
          toast.warn("Your loggin session is expired");
          navigator("/login");
        }
        setActionedTransferLoading(false);
        return;
      } else {
        toast.error(err?.response?.data?.message ?? "Something went wrong.");
      }
    } finally {
      setActionedTransferLoading(false);
    }
  };

  const handleRejectingWithdrawalRequest = async (transaction) => {
    const transactionId = transaction.transactionId;
    if (!rejectionReason || rejectionReason.length < 10) {
      toast.error("Please enter a valid reason for rejection.");
      return;
    }
    try {
      setRejectWithdrawLoading(true);
      const response = await axios.post("/admin/api/reject-withdraw-request/", {
        transactionId,
        reason: rejectionReason,
      });

      toast.success(
        response?.data?.message ?? "Withdraw rejected succcefully."
      );
      refreshData();
    } catch (err) {
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        const isLoggedOut = await logoutAdmin(false, () =>
          handleRejectingWithdrawalRequest(transaction)
        );
        if (isLoggedOut) {
          toast.warn("Your loggin session is expired");
          navigator("/login");
        }
        setRejectWithdrawLoading(false);
        return;
      } else {
        console.log(err);
        toast.error(err?.response?.data?.message ?? "Something went wrong.");
      }
    } finally {
      setRejectWithdrawLoading(false);
    }
  };
  const showTransactionDetailOfBonus = async (transaction) => {
    MySwal.fire({
      title: "Transaction Details",
      html: ReactDOMServer.renderToString(
        <TransactionDetail transaction={transaction} />
      ),
      showConfirmButton: true,
    });
  };
  const isItJustRequest = transaction.status === "pending";
  return (
    <div className="single-payment-card">
      <div className="single-payment-card-profile-wrapper">
        <Link
          title="User profile"
          to={"/users/" + transaction?.wallet?.users?.uid}
          className="profile-avatar-wrapper"
        >
          <AiOutlineUser className="profile-avatar" />
          <div className="profile-fullname">
            {transaction?.wallet?.users?.fullname}
          </div>

          <div
            className="profile-grade"
            style={{
              fontWeight: 900,
              fontSize: "1rem",
            }}
          >
            Current balance: {transaction?.wallet?.balance} ETB
          </div>
        </Link>
        <div
          className="profile-grade"
          style={{
            fontWeight: 900,
            color:
              isItJustRequest || transaction.status === "onorder"
                ? "orange"
                : transaction.status === "declined"
                ? "crimson"
                : null,
          }}
        >
          Status: {transaction.status}
        </div>
        <div
          className="profile-grade"
          style={{
            fontWeight: 900,
          }}
        >
          Withdraw bank: {transaction.bankName}
        </div>
      </div>

      <div className="single-payment-data">
        {transaction.isWithdraw ? (
          <>
            <BasicTitle title={"Withdrawal information:"} />
            <Tile
              title={"Withdrwal requested: "}
              info={moment(transaction.createdAt).fromNow()}
              icon={<AiOutlineFieldTime className="tile-icon" />}
            />
            <Tile
              title={"Withdrwal bank: "}
              info={transaction?.bankName?.toUpperCase()}
              icon={<AiOutlineHome className="tile-icon" />}
            />
            <Tile
              title={"Account holder name: "}
              info={transaction.accountName}
              icon={<AiOutlineTransaction className="tile-icon" />}
            />
            <Tile
              title={"Account number: "}
              info={transaction.accountNumber}
              icon={<AiOutlineTransaction className="tile-icon" />}
            />
            <Tile
              title={"Withdrwal TX.ref: "}
              info={transaction.autoTransferReference}
              icon={<AiOutlineTransaction className="tile-icon" />}
            />

            <Tile
              title={"Withdrwal Amount: "}
              info={transaction.amount + " ETB"}
              icon={<AiOutlineTransaction className="tile-icon" />}
            />
            <Tile
              title={"Withdrwal Status: "}
              info={transaction?.status?.toUpperCase()}
              icon={<AiOutlineTransaction className="tile-icon" />}
            />
            <div className="payment-verify-wrapper">
              <>
                <button
                  className="verify-button"
                  onClick={
                    isItJustRequest
                      ? transferLoading || actionedTransferLoading
                        ? null
                        : () =>
                            handleTransferingMoneyToUser(
                              transaction.transactionId
                            )
                      : () => handleActionedTransaction(transaction)
                  }
                  style={{
                    padding: "17px 12px",
                    background: isItJustRequest
                      ? "rgb(10, 188, 10)"
                      : "royalblue",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "space-evenly",
                    width: "13rem",
                    height: "50px",
                    fontSize: ".9rem",
                  }}
                >
                  {!transferLoading && !actionedTransferLoading ? (
                    <span className="verify-button-text">
                      {isItJustRequest
                        ? "Transfer Money to user"
                        : "Detail of withdraw."}
                    </span>
                  ) : (
                    <div
                      style={{
                        height: "12px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Loader width={40} height={12} color={"white"} />
                    </div>
                  )}

                  {isItJustRequest ? (
                    <AiOutlineTransaction className="verify-button-icon" />
                  ) : (
                    <AiFillInfoCircle className="verify-button-icon" />
                  )}
                </button>
              </>
            </div>

            {transaction.status !== "declined" ? (
              transaction.status === "pending" ? (
                <>
                  <textarea
                    placeholder="Enter reason for rejection"
                    value={rejectionReason}
                    onChange={(e) => {
                      setRejectionReason(e.target.value);
                    }}
                    style={{
                      width: "100%",
                      minHeight: "100px",
                      outline: "2px solid crimson",
                      margin: "8px 2px",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  ></textarea>
                  <button
                    className="verify-button payment-delete-button"
                    style={{
                      background: "crimson",
                      width: "20rem",
                      height: "50px",
                    }}
                    onClick={() =>
                      handleRejectingWithdrawalRequest(transaction)
                    }
                  >
                    {rejectWithdrawLoading ? (
                      <div
                        style={{
                          height: "12px",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Loader width={40} height={12} color={"white"} />
                      </div>
                    ) : (
                      <span className="verify-button-text">
                        Reject Withdrawal Request
                      </span>
                    )}
                    <AiOutlineDeleteColumn className="verify-button-icon" />
                  </button>
                </>
              ) : (
                <div
                  style={{
                    margin: "15px auto",
                    textAlign: "center",
                    fontWeight: 900,
                    color: "royalblue",
                  }}
                >
                  {" "}
                  Withdraw already made or it is in process.{" "}
                </div>
              )
            ) : (
              <>
                <h2
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Reason:
                </h2>
                <div>{transaction?.reason}</div>
                <div
                  style={{
                    margin: "15px auto",
                    textAlign: "center",
                    fontWeight: 900,
                    color: "crimson",
                  }}
                >
                  {" "}
                  Withdraw already rejected{" "}
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <Tile
              title={"Bonus Status: "}
              info={transaction?.status?.toUpperCase()}
              icon={<AiOutlineTransaction className="tile-icon" />}
            />
            <Tile
              title={"Bonus Amount: "}
              info={transaction.amount + " ETB"}
              icon={<AiOutlineTransaction className="tile-icon" />}
            />
            <Tile
              title={"Bonus related payment detail: "}
              info={transaction?.paymentId}
              icon={<AiOutlineTransaction className="tile-icon" />}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Link
                className="give-bonus-button"
                to={"/payment-details/" + transaction?.paymentId}
              >
                Link to paymen detail
              </Link>
              <button
                style={{
                  margin: "0 5px",
                }}
                className="give-bonus-button"
                onClick={() => {
                  showTransactionDetailOfBonus(transaction);
                }}
              >
                See Bonus Transaction
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function Tile({ title, info, icon }) {
  return (
    <div className="profile-info-tile">
      <div>
        {icon}
        <span className="tile-title">{title}:</span>
      </div>
      <span className="tile-data"> {info} </span>
    </div>
  );
}
