import React from "react";
import { BsAlarm, BsFillMenuButtonWideFill } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Loader from "../../common/Loader";
import BasicTitle from "../../common/BasicTitle";
import {
  AiFillPhone,
  AiFillUnlock,
  AiOutlineBook,
  AiOutlineDownload,
  AiOutlineFieldTime,
  AiOutlineMail,
  AiOutlineUser,
  AiOutlineReload,
  AiOutlineLogin,
  AiOutlineClear,
  AiOutlineBank,
  AiFillMoneyCollect,
  AiFillIdcard,
  AiOutlineTransaction,
} from "react-icons/ai";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

import moment from "moment";
import "./singleUser.css";
import axios from "axios";
import { toast } from "react-toastify";
import InfiniteScroll from "../../infiniteScroll/InfiniteScroll";
function SingleUser(props) {
  const { uid } = useParams();
  const { data, loading, error, refreshData } = useFetch(
    "/admin/api/users/" + uid
  );
  console.log(data);
  return (
    <div className="user-detail-page">
      <div className="user-detail-content-wrapper">
        {loading && (
          <div className="page-loading-wrapper">
            <div className="page-loading-wrapper">
              <Loader
                width={80}
                height={40}
                color={"#7175D6"}
                containerStyle={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            </div>
          </div>
        )}
        {data && <SingleUserCard data={data?.user} refreshData={refreshData} />}
        {error && !data && (
          <div className="page-loading-wrapper">
            <div className="no-data-wrapper">
              <BsFillMenuButtonWideFill className="no-data-icon" />

              <BasicTitle title="Something went wrong!!" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SingleUser;

function SingleUserCard({
  data: {
    fullname,
    grade,
    phone,
    email,
    createdAt,
    uid,
    freeTrialEnds,
    block,
    device,
    activity,
    subscription,
    isVerified,
    wallet,
  },
  refreshData,
}) {
  let downloadInformation = activity?.downloadInformation;
  if (downloadInformation) {
    try {
      downloadInformation = JSON.parse(downloadInformation);
    } catch (err) {
      console.log(err);
    }
  }
  const structureInfo =
    activity?.structureInfo == null
      ? null
      : JSON.parse(activity?.structureInfo);
  const { expirationDate, grade8, grade12n, grade12s } = subscription ?? {};
  const bankRequestInformation = activity?.bankRequestInformation
    ? JSON.parse(activity?.bankRequestInformation)
    : null;
  const handleVerification = async (plan) => {
    try {
      await axios.post("/admin/api/verify-payment?freePromo=freePromotion", {
        uid: uid,
        plan,
      });
      refreshData();
    } catch (err) {
      toast.error(err?.message ?? "Something went error!!");
    }
  };

  const howMuchLeft = (date) => {
    if (!date) return "not subscribed yet";
    const difference = moment(new Date(date)).diff(new Date());
    if (difference <= 0) return "It just past";
    const inDays = Math.ceil(difference / (1000 * 60 * 60 * 24));
    return `${inDays} days left`;
  };
  const resetUserDevice = async (uid) => {
    try {
      await axios.post("/admin/api/reset-user-device", { uid });
      refreshData();
    } catch (err) {
      toast.error(err?.response?.data?.message ?? "Something went wrong");
    }
  };
  const handleBlockUser = async (uid) => {
    try {
      await axios.post("/admin/api/block-user", { uid });
      refreshData();
    } catch (err) {
      toast.error(err?.response?.data?.message ?? "Something went wrong");
    }
  };

  const handleVerifyUser = async (uid) => {
    try {
      await axios.post("/admin/api/verify-user", { uid });
      refreshData();
    } catch (err) {
      toast.error(err?.response?.data?.message ?? "Something went wrong");
    }
  };

  return (
    <div className="single-user-card">
      <div className="single-user-card-profile-wrapper">
        <div className="profile-avatar-wrapper">
          <AiOutlineUser className="profile-avatar" />
          <div className="profile-fullname">{fullname}</div>
          <div className={"verification-flag verification-flag-" + isVerified}>
            {" "}
            {isVerified ? "Verified" : "Not Verified"}{" "}
          </div>
        </div>

        <div className="profile-grade">{grade}</div>
        <button
          className="payment-detail-refresh-icon-button"
          onClick={refreshData}
        >
          Refresh
          <AiOutlineReload className="payment-detail-refresh-icon" />
        </button>
      </div>

      <div className="profile-info-table">
        <BasicTitle title={"User detail"} />
        <Tile
          title={"phone"}
          info={phone}
          icon={<AiFillPhone className="tile-icon" />}
        />
        <Tile
          title={"Email"}
          info={email}
          icon={<AiOutlineMail className="tile-icon" />}
        />
        <Tile
          title={"Bank Request"}
          info={
            bankRequestInformation == null
              ? 0
              : bankRequestInformation?.length + " times "
          }
          icon={<AiOutlineBank className="tile-icon" />}
        />
        <Tile
          title={"Verification Code"}
          info={
            activity?.requestCodeInformation == null
              ? 0
              : JSON.parse(activity?.requestCodeInformation)?.length + " times "
          }
          icon={<AiOutlineDownload className="tile-icon" />}
        />
        <Tile
          title={"Download Count"}
          info={downloadInformation?.length ?? 0}
          icon={<AiOutlineDownload className="tile-icon" />}
        />
        <Tile
          title={"Last download"}
          info={
            downloadInformation
              ? moment(
                  new Date(
                    downloadInformation?.sort(
                      (a, b) => b.date - a.date
                    )[0]?.date
                  )
                ).fromNow()
              : "No Download"
          }
          icon={<AiOutlineDownload className="tile-icon" />}
        />
        <Tile
          title={"Last login"}
          info={
            activity?.loginInformation
              ? moment(
                  new Date(
                    JSON.parse(activity?.loginInformation)?.sort(
                      (a, b) => b - a
                    )[0]
                  )
                ).fromNow()
              : "No Login"
          }
          icon={<AiOutlineLogin className="tile-icon" />}
        />
        <Tile
          title={"Structure Version"}
          info={structureInfo?.structureVersion ?? "null"}
          icon={<AiOutlineDownload className="tile-icon" />}
        />
        <Tile
          title={"Structure Update"}
          info={
            structureInfo?.date == null
              ? "null"
              : moment(new Date(structureInfo?.date)).fromNow()
          }
          icon={<AiOutlineDownload className="tile-icon" />}
        />

        <Tile
          title={"Acc. Created At"}
          info={moment(createdAt).fromNow()}
          icon={<AiOutlineFieldTime className="tile-icon" />}
        />

        <BasicTitle title={"Subscription"} />
        <Tile
          title={"Grade 8"}
          info={
            grade8 ? (
              "Subscribed"
            ) : (
              <TileButton
                handleVerification={() => handleVerification("grade8")}
                plan={"grade8"}
              />
            )
          }
          icon={<AiOutlineBook className="tile-icon" />}
        />
        <Tile
          title={"Grade 12 natural"}
          info={
            grade12n ? (
              "Subscribed"
            ) : (
              <TileButton
                handleVerification={() => handleVerification("grade12n")}
                plan={"grade12n"}
              />
            )
          }
          icon={<AiOutlineBook className="tile-icon" />}
        />
        <Tile
          title={"Grade 12 social"}
          info={
            grade12s ? (
              "Subscribed"
            ) : (
              <TileButton
                handleVerification={() => handleVerification("grade12s")}
                plan={"grade12s"}
              />
            )
          }
          icon={<AiOutlineBook className="tile-icon" />}
        />

        <BasicTitle title={"Access "} />

        <Tile
          title={"Account expiration:"}
          info={howMuchLeft(expirationDate)}
          icon={<AiOutlineFieldTime className="tile-icon" />}
        />
        <Tile
          title={"Freetrial:"}
          info={howMuchLeft(freeTrialEnds)}
          icon={<AiOutlineFieldTime className="tile-icon" />}
        />
        <BasicTitle title={"Device "} />
        <Tile
          title={"Device:"}
          info={device ?? "No device associated"}
          icon={<AiOutlineFieldTime className="tile-icon" />}
        />
        {device !== null && (
          <div className="user-detail-refresh-wrapper">
            {" "}
            <button
              className="payment-detail-refresh-icon-button"
              onClick={() => resetUserDevice(uid)}
            >
              Reset user device
              <AiOutlineClear className="payment-detail-refresh-icon" />
            </button>{" "}
          </div>
        )}
        <BasicTitle title={"Wallet"} />
        <Tile
          title={"Walllet Id:"}
          info={wallet?.walletId ?? "No wallet Id"}
          icon={<AiFillIdcard className="tile-icon" />}
        />
        <Tile
          title={"Balance:"}
          info={`${wallet?.balance ?? 0} ETB`}
          icon={<AiFillMoneyCollect className="tile-icon" />}
        />
        <Tile
          title={"Total Earn:"}
          info={`${wallet?.totalEarn ?? 0} ETB`}
          icon={<AiFillMoneyCollect className="tile-icon" />}
        />
        {/* <Tile
          title={"Transactions:"}
          info={wallet.trasactions?.length ?? 0}
          icon={<AiOutlineTransaction className="tile-icon" />}
        /> */}
        <BasicTitle title={"Verify User - To verify users phone"} />
        <button
          className={
            isVerified
              ? "user-detail-block-icon-button"
              : "user-detail-unblock-icon-button"
          }
          onClick={() => handleVerifyUser(uid)}
        >
          {isVerified ? "UnVerify user" : "Verify user"}
          <IoMdCheckmarkCircleOutline className="payment-detail-refresh-icon" />
        </button>

        <BasicTitle title={"Access Denial - To block users from using "} />
        <button
          className={
            !block
              ? "user-detail-block-icon-button"
              : "user-detail-unblock-icon-button"
          }
          onClick={() => handleBlockUser(uid)}
        >
          {block ? "Unblock user" : "Block user"}
          <AiOutlineClear className="payment-detail-refresh-icon" />
        </button>
        <BasicTitle title={" ____________________ "} />
        <BasicTitle title={"User transactions records"} />
        <InfiniteScroll
          url={"/admin/api/transactions/" + wallet?.walletId}
          additionalParams={null}
          dataKey={"transactions"}
        >
          {function (transactions, lastElementRef) {
            return transactions.length === 0 ? (
              <div className="page-loading-wrapper">
                <div className="no-data-wrapper">
                  <BsFillMenuButtonWideFill className="no-data-icon" />

                  <BasicTitle title="There is no transactions in the docket" />
                </div>
              </div>
            ) : (
              <>
                {transactions.map((transaction, index) => (
                  <TransactionCard
                    key={transaction.transactionId}
                    transaction={transaction}
                    lastElementRef={
                      index === transaction.length - 1 ? lastElementRef : null
                    }
                  />
                ))}
              </>
            );
          }}
        </InfiniteScroll>
      </div>
    </div>
  );
}

function Tile({ title, info, icon }) {
  return (
    <div className="profile-info-tile">
      <div>
        {icon}
        <span className="tile-title">{title}:</span>
      </div>
      <span
        className="tile-data"
        style={{
          wordBreak: "break-all",
          wordWrap: "wrap",
        }}
      >
        {" "}
        {info}{" "}
      </span>
    </div>
  );
}

function TileButton({ handleVerification, plan }) {
  return (
    <button className="verify-button" onClick={handleVerification}>
      <span className="verify-button-text"> Open acccout </span>
      <AiFillUnlock className="verify-button-icon" />
    </button>
  );
}

function TransactionCard({ transaction, lastElementRef }) {
  const timing = moment(transaction.createdAt).fromNow();
  return (
    <Link
      className="payment-detail-card"
      to={"/withdrawal-transaction/" + transaction.transactionId}
      ref={lastElementRef}
      id={transaction.transactionId}
    >
      {/* {JSON.stringify(transaction)} */}
      <div className="upper-payment-card">
        <h5 className="payment-card-name">
          <AiOutlineTransaction className="payment-card-name-icon" />
          {transaction?.type?.toUpperCase()}
          <span className="withdrwal-transaction-bank-name">
            {transaction.bankName}
          </span>
        </h5>
        <div className="payment-card-time">
          {" "}
          <BsAlarm className="payment-card-time-icon" /> {timing}
        </div>
      </div>
      <div className="bottom-payment-card">
        <div className="payment-card-phone">
          Tx.Ref: {transaction.autoTransferReference}
        </div>

        <div className="payment-card-bank">
          <div
            className="profile-grade"
            style={{
              fontWeight: 900,
              color:
                transaction.status === "pending" ||
                transaction.status === "onorder"
                  ? "orange"
                  : transaction.status === "declined"
                  ? "crimson"
                  : null,
            }}
          >
            Status: {transaction.status}
          </div>{" "}
          Amount: {transaction.amount} ETB
        </div>
      </div>
    </Link>
  );
}
