import React from "react";

function BasicTitle({ title, className = "", style = {} }) {
  return (
    <h2 className={`basic-title ${className}`} style={style}>
      {title}
    </h2>
  );
}

export default BasicTitle;
