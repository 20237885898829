import { createContext, useCallback, useContext, useReducer } from "react";
import getAdmin from "../util/getAdminFromStorage";
import {
  ALL_PAYMENT_DETAILS,
  AUTOMATED_TOGGLE,
  DECLINED_WITHDRAWAL,
  EXAM_SEARCH_QUERY,
  FETCH_ACTIVITIES,
  FETCH_DOWNLOAD,
  FETCH_PAYMENT_DETAIL,
  FETCH_SUBSCRIPTION,
  FETCH_USERS,
  FETCH_WHOLE_STAT,
  LOG_ADMIN,
  LOGOUT_ADMIN,
  MANUAL_TOGGLE,
  ONORDER_WITHDRAWAL,
  PENDING_WITHDRAWAL,
  REJECTED_TOGGLE,
  SCHOOL_SEARCH_QUERY,
  SUCCESS_WITHDRAWAL,
  USER_SEARCH_QUERY,
  VERIFIED_TOGGLE,
  WITHDRAWAL_TRANSACTION_QUERY,
} from "./actions";
import saveAdmin from "../util/saveAdminToStorage";
import { saveAccessToken } from "../util/accessToken";
import axios from "axios";
import { toast } from "react-toastify";
import { logoutAdminTotally } from "../util/logoutAdminTotally";

const AppContext = createContext();

// Define initial state
const initialState = {
  admin: getAdmin(),
  paymentDetail: null,
  users: null,
  subscriptions: null,
  download: null,
  activities: null,
  wholeStat: null,
  verified: false,
  rejected: false,
  automated: false,
  manual: false,
  allPaymentDetails: false,

  pendingWithdrawal: true,
  onorderWithdrawal: false,
  successWithdrawal: false,
  declinedWithdrawal: false,
  transactionSearch: "",

  userSearch: "",
  schoolSearch: "",
  examSearch: "",
};
// Define reducer function
const appReducer = (state, action) => {
  switch (action.type) {
    case LOG_ADMIN:
      return { ...state, admin: action.payload };
    case LOGOUT_ADMIN:
      return { ...state, admin: null };
    case FETCH_PAYMENT_DETAIL:
      return { ...state, paymentDetail: action.payload };
    case FETCH_USERS:
      return { ...state, users: action.payload };
    case FETCH_ACTIVITIES:
      return { ...state, activities: action.payload };
    case FETCH_SUBSCRIPTION:
      return { ...state, subscriptions: action.payload };
    case FETCH_DOWNLOAD:
      return { ...state, download: action.payload };
    case FETCH_WHOLE_STAT:
      return { ...state, wholeStat: action.payload };
    case VERIFIED_TOGGLE:
      return { ...state, verified: !state.verified };
    case REJECTED_TOGGLE:
      return { ...state, rejected: !state.rejected };
    case AUTOMATED_TOGGLE:
      return { ...state, automated: !state.automated, manual: false };
    case MANUAL_TOGGLE:
      return { ...state, manual: !state.manual, automated: false };
    case ALL_PAYMENT_DETAILS:
      return { ...state, allPaymentDetails: !state.allPaymentDetails };

    case PENDING_WITHDRAWAL:
      return { ...state, pendingWithdrawal: !state.pendingWithdrawal };
    case ONORDER_WITHDRAWAL:
      return { ...state, onorderWithdrawal: !state.onorderWithdrawal };
    case SUCCESS_WITHDRAWAL:
      return { ...state, successWithdrawal: !state.successWithdrawal };
    case DECLINED_WITHDRAWAL:
      return { ...state, declinedWithdrawal: !state.declinedWithdrawal };

    case USER_SEARCH_QUERY:
      return { ...state, userSearch: action.payload };

    case SCHOOL_SEARCH_QUERY:
      return { ...state, schoolSearch: action.payload };

    case WITHDRAWAL_TRANSACTION_QUERY:
      return { ...state, transactionSearch: action.payload };
    case EXAM_SEARCH_QUERY:
      return { ...state, examSearch: action.payload };
    default:
      return state;
  }
};

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);
  const logAdmin = (admin, accessToken) => {
    saveAdmin(admin);
    saveAccessToken(accessToken);
    dispatch({ type: LOG_ADMIN, payload: admin });
  };
  const logoutAdmin = async (forced = false, retry) => {
    if (!forced) {
      try {
        const resp = await axios.post("/admin/api/refresh-token");
        console.log(resp);
        if (resp.status === 200) {
          const accessToken = resp.data.accessToken;
          saveAccessToken(accessToken);
          if (retry) {
            retry();
          }
          return;
        }
      } catch (err) {
        console.log(err);
      }
    }
    logoutAdminTotally();
    dispatch({ type: LOGOUT_ADMIN });
    return true;
  };

  // const fetchPaymentData = useCallback(async (admin) => {
  //   try {
  //     const paymentDetail = await axios.get("/admin/api/payment-details");
  //     dispatch({
  //       type: FETCH_PAYMENT_DETAIL,
  //       payload: paymentDetail?.data?.paymentDetails,
  //     });
  //   } catch (err) {
  //     if (err?.response?.status === 401 || err?.response?.status === 403) {
  //       logoutAdmin();
  //       toast.warn("Your loggin session is expired");
  //       return;
  //     }

  //     toast.error(err.message);
  //   }
  // }, []);

  // const fetchUsers = useCallback(async (admin) => {
  //   try {
  //     const resp = await axios.get("/admin/api/users");
  //     dispatch({
  //       type: FETCH_USERS,
  //       payload: resp?.data?.users,
  //     });
  //   } catch (err) {
  //     if (err?.response?.status === 401 || err?.response?.status === 403) {
  //       console.log(err);
  //       logoutAdmin();
  //       toast.warn("Your loggin session is expired");
  //       return;
  //     }

  //     toast.error(err.message);
  //   }
  // }, []);
  // const fetchActivities = useCallback(async (admin) => {
  //   try {
  //     const resp = await axios.get("/admin/api/activities");
  //     dispatch({
  //       type: FETCH_ACTIVITIES,
  //       payload: resp?.data?.activities,
  //     });
  //   } catch (err) {
  //     if (err?.response?.status === 401 || err?.response?.status === 403) {
  //       console.log(err);
  //       logoutAdmin();
  //       toast.warn("Your loggin session is expired");
  //       return;
  //     }

  //     toast.error(err.message);
  //   }
  // }, []);
  // const fetchSubscripions = useCallback(async (admin) => {
  //   try {
  //     const resp = await axios.get("/admin/api/subscriptions");
  //     console.log(resp);
  //     dispatch({
  //       type: FETCH_SUBSCRIPTION,
  //       payload: resp?.data?.subscriptions,
  //     });
  //   } catch (err) {
  //     if (err?.response?.status === 401 || err?.response?.status === 403) {
  //       console.log(err);
  //       logoutAdmin();
  //       toast.warn("Your loggin session is expired");
  //       return;
  //     }

  //     toast.error(err.message);
  //   }
  // }, []);

  const fetchDownload = useCallback(async (admin) => {
    try {
      const resp = await axios.get("/admin/api/app-download");
      dispatch({
        type: FETCH_DOWNLOAD,
        payload: resp?.data?.download,
      });
    } catch (err) {
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        console.log(err);
        logoutAdmin();
        toast.warn("Your loggin session is expired");
        return;
      }

      toast.error(err.message);
    }
  }, []);

  const updateUsers = useCallback(async (users) => {
    dispatch({
      type: FETCH_USERS,
      payload: users,
    });
  }, []);
  const fetchWholeStat = useCallback(async (admin) => {
    try {
      const resp = await axios.get("/admin/api/whole-stat");
      dispatch({
        type: FETCH_WHOLE_STAT,
        payload: resp?.data?.wholeStat,
      });
    } catch (err) {
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        console.log(err);
        logoutAdmin();
        toast.warn("Your loggin session is expired");
        return;
      }

      toast.error(err.message);
    }
  }, []);
  const toggleVerified = () => {
    dispatch({ type: VERIFIED_TOGGLE });
  };
  const toggleRejected = () => {
    dispatch({ type: REJECTED_TOGGLE });
  };
  const toggleAutomated = () => {
    dispatch({ type: AUTOMATED_TOGGLE });
  };
  const toggleManual = () => {
    dispatch({ type: MANUAL_TOGGLE });
  };

  const toggleAllPaymentDetails = () => {
    dispatch({ type: ALL_PAYMENT_DETAILS });
  };
  const togglePendingWithdrawal = () => {
    dispatch({ type: PENDING_WITHDRAWAL });
  };
  const toggleOnorderWithdrawal = () => {
    dispatch({ type: ONORDER_WITHDRAWAL });
  };

  const toggleSuccessWithdrawal = () => {
    dispatch({ type: SUCCESS_WITHDRAWAL });
  };

  const toggleDeclinedWithdrawal = () => {
    dispatch({ type: DECLINED_WITHDRAWAL });
  };

  const searchUser = (query) => {
    dispatch({ type: USER_SEARCH_QUERY, payload: query });
  };
  const searchSchool = (query) => {
    dispatch({ type: SCHOOL_SEARCH_QUERY, payload: query });
  };
  const searchWithdrawalTransaction = (query) => {
    dispatch({ type: WITHDRAWAL_TRANSACTION_QUERY, payload: query });
  };

  const searchExam = (query) => {
    dispatch({ type: EXAM_SEARCH_QUERY, payload: query });
  };
  return (
    <AppContext.Provider
      value={{
        toggleRejected,
        toggleVerified,
        toggleAutomated,
        toggleManual,
        toggleAllPaymentDetails,

        togglePendingWithdrawal,
        toggleSuccessWithdrawal,
        toggleDeclinedWithdrawal,
        toggleOnorderWithdrawal,

        searchUser,
        searchSchool,
        searchWithdrawalTransaction,
        searchExam,
        state,
        logAdmin,
        logoutAdmin,
        // fetchPaymentData,
        // fetchUsers,
        // fetchSubscripions,
        fetchDownload,
        // fetchActivities,
        updateUsers,
        fetchWholeStat,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useProviderContext = () => {
  return useContext(AppContext);
};
