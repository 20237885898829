import React, { useState } from "react";
import {
  AiOutlineUser,
  AiOutlineLogout,
  AiOutlineClose,
  AiOutlineDollar,
  AiOutlineAccountBook,
  AiOutlineDashboard,
  AiOutlineMan,
  AiOutlineBank,
  AiOutlineFieldTime,
  AiOutlineDownSquare,
  AiOutlineMail,
  AiFillSecurityScan,
  AiFillMoneyCollect,
  AiFillHome,
} from "react-icons/ai";
import { RiDownloadCloudFill } from "react-icons/ri";

import { HiOutlineMenuAlt2 } from "react-icons/hi";

import "./header.css";
import { useProviderContext } from "../provider/appProvider";
import { useNavigate } from "react-router-dom";
import { SUPER_ADMIN_DOMAIN } from "../../global";

function AdminNavBar(props) {
  const navigator = useNavigate();
  const {
    logoutAdmin,
    state: { admin },
  } = useProviderContext();
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const handleLogout = (e) => {
    logoutAdmin(true);
    navigator("/login");
  };
  return (
    <div className="admin-navbar-wrapper">
      <div className="admin-navbar">
        <div className="admin-navbar-menu-icon-wrapper">
          <HiOutlineMenuAlt2
            className="admin-navbar-menu-icon"
            onClick={() => setIsSideBarOpen(true)}
          />
        </div>
        <div className="admin-navbar-identity">
          <button onClick={handleLogout} className="admin-navbar-logout">
            <p> Logout </p> <AiOutlineLogout style={{ marginLeft: 10 }} />
          </button>
          <a href={SUPER_ADMIN_DOMAIN} target="_blank" rel="noreferrer">
            <div className="admin-navbar-avatar">
              <p className="avatar-name">
                <AiFillSecurityScan />
              </p>
            </div>
          </a>
        </div>{" "}
      </div>
      {isSideBarOpen && (
        <AdminSlideBar setIsSideBarOpen={setIsSideBarOpen} admin={admin} />
      )}
    </div>
  );
}

function AdminSlideBar(props) {
  const closeSideBar = () => {
    props.setIsSideBarOpen(false);
  };
  const handleSideBarVanish = (e) => {
    if (
      e.target.id === "admin-fixed-bar" ||
      e.target.id === "admin-fixed-icon" ||
      e.target.id === "menu-card"
    ) {
      closeSideBar();
    }
  };

  return (
    <div
      className="admin-slide-bar-container"
      onClick={handleSideBarVanish}
      id="admin-fixed-bar"
    >
      <div className="admin-slide-bar-wrapper admin-slide-bar-wrapper-animate">
        <div className="admin-slide-bar">
          <div className="admin-slide-bar-topic">
            <h3 className="admin-slide-bar-title">FREEDOM ADMIN </h3>
            <div className="admin-slide-bar-user">
              {/* {props.admin.email } */}
              <div className="username"> {props.admin.username} </div>
              <AiOutlineUser size={30} className="admin-slide-bar-user-icon" />
            </div>
          </div>
        </div>
        <div className="slide-bar-vanisher roboto">
          <AiOutlineClose
            id="admin-fixed-icon"
            className="admin-fixed-icon"
            onClick={handleSideBarVanish}
          ></AiOutlineClose>
        </div>
        <SidebarMenus close={closeSideBar} />
      </div>
    </div>
  );
}

export default AdminNavBar;

function SidebarMenus(props) {
  return (
    <div
      className="side-bar-menu-wrapper"
      style={{
        overflowY: "scroll",
        maxHeight: "100vh",
      }}
    >
      <MenuCard
        title={"Withdrawal Transaction"}
        icon={<AiFillMoneyCollect className="menu-card-icon" />}
        close={props.close}
        to="/withdrawal-transactions"
      />
      <MenuCard
        title={"Payment Info"}
        icon={<AiOutlineDollar className="menu-card-icon" />}
        close={props.close}
        to="/"
      />
      <MenuCard
        title={"All users"}
        icon={<AiOutlineUser className="menu-card-icon" />}
        close={props.close}
        to="/users"
      />
      <MenuCard
        title={"Users Statistics"}
        icon={<AiOutlineDashboard className="menu-card-icon" />}
        close={props.close}
        to="/user-statistics"
      />
      <MenuCard
        title={"Exam Statistics"}
        icon={<AiOutlineAccountBook className="menu-card-icon" />}
        close={props.close}
        to="/exam-statistics"
      />
      <MenuCard
        title={"Schools"}
        icon={<AiFillHome className="menu-card-icon" />}
        close={props.close}
        to="/schools"
      />{" "}
      <MenuCard
        title={"Exam Store"}
        icon={<AiOutlineAccountBook className="menu-card-icon" />}
        close={props.close}
        to="/exam-store"
      />
      <MenuCard
        title={"Exam Cook"}
        icon={<AiOutlineAccountBook className="menu-card-icon" />}
        close={props.close}
        to="/exam-cook"
      />
      <MenuCard
        title={"Top Downloaders"}
        icon={<RiDownloadCloudFill className="menu-card-icon" />}
        close={props.close}
        to="/top-downloaders"
      />
      <MenuCard
        title={"Recent Downloaders"}
        icon={<AiOutlineFieldTime className="menu-card-icon" />}
        close={props.close}
        to="/recent-downloaders"
      />
      <MenuCard
        title={"Bank Requests"}
        icon={<AiOutlineBank className="menu-card-icon" />}
        close={props.close}
        to="/bank-requests"
      />
      <MenuCard
        title={"Email Users"}
        icon={<AiOutlineMail className="menu-card-icon" />}
        close={props.close}
        to="/email-users"
      />
      <MenuCard
        title={"App Download"}
        icon={<AiOutlineDownSquare className="menu-card-icon" />}
        close={props.close}
        to="/app-download-statics"
      />
      <MenuCard
        title={"Hey admin"}
        icon={<AiOutlineMan className="menu-card-icon" />}
        close={props.close}
        to="/admin"
      />
    </div>
  );
}

function MenuCard({ title, icon, close, to }) {
  const navigator = useNavigate();
  return (
    <div
      id="menu-card"
      className="menu-card"
      onClick={() => {
        close();
        navigator(to);
      }}
    >
      <div> {title} </div>
      {icon}
    </div>
  );
}
