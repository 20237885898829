import React, { useEffect } from "react";
import {
  AiOutlineDollarCircle,
  AiOutlineBook,
  AiOutlineDashboard,
  AiOutlineDollar,
  AiOutlineGift,
  AiOutlinePercentage,
  AiOutlinePlusCircle,
  AiOutlineReload,
  AiOutlineUser,
} from "react-icons/ai";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

import { useProviderContext } from "../../provider/appProvider";
import "./userStatistics.css";
import Loader from "../../common/Loader";

import StaticsBox from "../../common/Statistics";
function UserStatistics(props) {
  const {
    state: { wholeStat },
    fetchWholeStat,
  } = useProviderContext();

  const gradeStat = JSON.parse(wholeStat?.usersByGrade ?? "{}");
  const appVersionCount = JSON.parse(wholeStat?.appVersionCount ?? "{}");
  const structureVersionCount = JSON.parse(
    wholeStat?.structureVersionCount ?? "{}"
  );
  const refreshData = () => {
    fetchWholeStat();
  };

  useEffect(() => {
    fetchWholeStat();
  }, [fetchWholeStat]);

  return (
    <div className="payment-detail-page">
      <div className="payment-detail-content-wrapper">
        <div className="payment-detail-header">
          <h1 className="common-pages-title"> User statics </h1>
          <div className="payment-detail-refresh-wrapper">
            <button
              className="payment-detail-refresh-icon-button"
              onClick={refreshData}
            >
              Refresh
              <AiOutlineReload className="payment-detail-refresh-icon" />
            </button>
            <AiOutlineDashboard className="payment-detail-icon" />
          </div>
        </div>
        {!wholeStat ? (
          <div className="page-loading-wrapper">
            <div className="page-loading-wrapper">
              <Loader
                width={80}
                height={40}
                color={"#7175D6"}
                containerStyle={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            </div>
          </div>
        ) : (
          <>
            <div className="statics-title-top">Users</div>
            <div className="statics-wrapper">
              <StaticsBox
                data={wholeStat.totalUsers}
                title={"All Total Users"}
                type={1}
                icon={<AiOutlineUser className="statics-icon statics-icon-1" />}
              />
              <StaticsBox
                data={wholeStat.totalSubscribedUsers}
                title={"Subscribed Users"}
                type={3}
                icon={
                  <AiOutlineDollarCircle className="statics-icon statics-icon-3" />
                }
              />
              <StaticsBox
                data={
                  (wholeStat.totalSubscribedUsers / wholeStat.totalUsers) *
                    100 +
                  "%"
                }
                title={"Percentage Subscription"}
                type={2}
                icon={
                  <AiOutlinePercentage className="statics-icon statics-icon-2" />
                }
              />
              <StaticsBox
                data={wholeStat.freeTrialUsers}
                title={"Freetrial Users"}
                type={1}
                icon={<AiOutlineGift className="statics-icon statics-icon-1" />}
              />
              <StaticsBox
                data={wholeStat.verifiedUsers}
                title={"Verified Users"}
                type={2}
                icon={
                  <IoMdCheckmarkCircleOutline className="statics-icon statics-icon-2" />
                }
              />

              <StaticsBox
                data={
                  Math.round(
                    (wholeStat.verifiedUsers / wholeStat.totalUsers) * 100
                  ) + "%"
                }
                title={"Verified percentage"}
                type={1}
                icon={
                  <IoMdCheckmarkCircleOutline className="statics-icon statics-icon-1" />
                }
              />
            </div>

            <div className="statics-title-top">Vesrion Statics</div>
            <div className="statics-wrapper">
              {Object.keys(appVersionCount).map((version) => {
                return (
                  <div className={"statics-box"} key={version}>
                    <h2 className={`statics-title statics-title-1`}>
                      <AiOutlineReload className="statics-icon statics-icon-1" />
                      Version control
                    </h2>
                    <div className="version-tile">
                      <div className="version-head">
                        <AiOutlineReload className="statics-icon statics-icon-1" />
                        <div className="version-name"> {version} </div>
                      </div>
                      <div className="version-count">
                        {appVersionCount[version]}{" "}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="statics-title-top">Structure Vesrion</div>
            <div className="statics-wrapper">
              {Object.keys(structureVersionCount).map((key) => {
                return (
                  <StaticsBox
                    data={structureVersionCount[key]}
                    title={"Version " + key}
                    type={1}
                    key={key}
                    icon={
                      <AiOutlineReload className="statics-icon statics-icon-1" />
                    }
                  />
                );
              })}
            </div>

            <div className="statics-title-top">Periodic registration</div>
            <div className="statics-wrapper">
              <StaticsBox
                data={wholeStat.usersRegisteredInWeek}
                title={"Weekly registration"}
                type={1}
                icon={
                  <AiOutlinePlusCircle className="statics-icon statics-icon-1" />
                }
              />
              <StaticsBox
                data={wholeStat.usersRegisteredInMonth}
                title={"Monthly registration"}
                type={3}
                icon={
                  <AiOutlinePlusCircle className="statics-icon statics-icon-3" />
                }
              />
            </div>

            <div className="statics-title-top">Periodic subscription</div>
            <div className="statics-wrapper">
              <StaticsBox
                data={wholeStat.usersSubscribedInWeek}
                title={"Weekly subscription"}
                type={2}
                icon={
                  <AiOutlineDollar className="statics-icon statics-icon-2" />
                }
              />
              <StaticsBox
                data={wholeStat.usersSubscribedInMonth}
                title={"Monthly subscription"}
                type={1}
                icon={
                  <AiOutlineDollar className="statics-icon statics-icon-1" />
                }
              />
            </div>

            <div className="statics-title-top">Grade</div>
            <div className="statics-wrapper">
              <StaticsBox
                data={gradeStat?.grade8}
                title={"Grade 8 students"}
                type={3}
                icon={<AiOutlineBook className="statics-icon statics-icon-3" />}
              />
              <StaticsBox
                data={gradeStat?.grade10}
                title={"Grade 10 students"}
                type={1}
                icon={<AiOutlineBook className="statics-icon statics-icon-1" />}
              />
              <StaticsBox
                data={gradeStat?.grade12n}
                title={"Grade 12 natural students"}
                type={1}
                icon={<AiOutlineBook className="statics-icon statics-icon-1" />}
              />
              <StaticsBox
                data={gradeStat?.grade12s}
                title={"Grade 12 social students"}
                type={2}
                icon={<AiOutlineBook className="statics-icon statics-icon-2" />}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default UserStatistics;
