import React, { useState } from "react";
import { BsFillMenuButtonWideFill } from "react-icons/bs";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Loader from "../../common/Loader";
import BasicTitle from "../../common/BasicTitle";
import {
  AiOutlineUser,
  AiOutlineReload,
  AiFillIdcard,
  AiOutlinePushpin,
} from "react-icons/ai";

import InfiniteScroll from "../../infiniteScroll/InfiniteScroll";
import ListTile from "../SinglePaymentDetail/ListTile";
import { UserDetailCard } from "../AllUsers/AlllUsers";
import WithdrawForm from "./WithdrawForm";

import ReactDOMServer from "react-dom/server";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import SweetAlert2 from "react-sweetalert2";
import ReactDOM from "react-dom";
import { ref } from "yup";
const MySwal = withReactContent(Swal);

function SingleSchool(props) {
  const { schoolCode } = useParams();
  const { data, loading, error, refreshData } = useFetch(
    "/admin/api/schools/" + schoolCode
  );
  console.log(data);
  return (
    <div className="user-detail-page">
      <div className="user-detail-content-wrapper">
        {loading && (
          <div className="page-loading-wrapper">
            <Loader
              width={80}
              height={40}
              color={"#7175D6"}
              containerStyle={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          </div>
        )}
        {data && (
          <SingleSchoolCard
            data={data?.school}
            studentsCount={data?.studentsCount}
            refreshData={refreshData}
          />
        )}
        {error && !data && !loading && (
          <div className="page-loading-wrapper">
            <div className="no-data-wrapper">
              <BsFillMenuButtonWideFill className="no-data-icon" />

              <BasicTitle title="Something went wrong!!" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SingleSchool;

function SingleSchoolCard({
  data: {
    schoolName,
    schoolCode,
    verified,
    balance,
    school_agents,
    school_bonus_transaction,
  },
  studentsCount,
  refreshData,
}) {
  const [swalProps, setSwalProps] = useState(false);

  function handleClick() {
    setSwalProps(true);
  }
  function removePopup() {
    setSwalProps(false);
  }
  return (
    <div className="single-user-card">
      <div className="single-user-card-profile-wrapper">
        <div className="profile-avatar-wrapper">
          <AiOutlineUser className="profile-avatar" />
          <div className="profile-fullname">
            {schoolName}
            <div>Balance: {balance} ETB</div>
          </div>
          <div className={"verification-flag verification-flag-" + verified}>
            {" "}
            {verified ? "Verified" : "Not Verified"}{" "}
          </div>
        </div>

        <div
          className="profile-grade"
          style={{
            fontWeight: 900,
          }}
        >
          {" "}
          Total Users:{" "}
          {studentsCount.reduce((acc, grade) => acc + grade?._count?.grade, 0)}
        </div>
        <button
          className="payment-detail-refresh-icon-button"
          onClick={refreshData}
        >
          Refresh
          <AiOutlineReload className="payment-detail-refresh-icon" />
        </button>
      </div>

      <div className="profile-info-table">
        <BasicTitle title={"User detail"} />
        <Tile
          title={"School Code"}
          info={schoolCode}
          icon={<AiFillIdcard className="tile-icon" />}
        />
        <Tile
          title={"Grade 8"}
          info={
            studentsCount?.filter((stat) => stat.grade === "grade8")[0]?._count
              ?.grade ?? "-"
          }
          icon={<AiOutlineUser className="tile-icon" />}
        />

        <Tile
          title={"Grade 10"}
          info={
            studentsCount?.filter((stat) => stat.grade === "grade10")[0]?._count
              ?.grade ?? "-"
          }
          icon={<AiOutlineUser className="tile-icon" />}
        />
        <Tile
          title={"Grade 12 natural"}
          info={
            studentsCount?.filter((stat) => stat.grade === "grade12n")[0]
              ?._count?.grade ?? "-"
          }
          icon={<AiOutlineUser className="tile-icon" />}
        />
        <Tile
          title={"Grade 12 social"}
          info={
            studentsCount?.filter((stat) => stat.grade === "grade12s")[0]
              ?._count?.grade ?? "-"
          }
          icon={<AiOutlineUser className="tile-icon" />}
        />

        <BasicTitle title={"Agents (Total " + school_agents?.length + ")"} />

        {school_agents?.length > 0 ? (
          school_agents.map((agent, i) => (
            <ListTile
              key={i}
              leading={i + 1}
              title={agent.fullname}
              subtitle={agent.phone}
              color={"crimson"}
              info={agent.superAgent ? "Super Agent" : "Agent"}
            />
          ))
        ) : (
          <BasicTitle
            style={{
              textAlign: "center",
              margin: "10px 0",
            }}
            title={"No agent"}
          />
        )}
        <button className="verified-button" onClick={handleClick}>
          <span className="verify-button-text"> Withdraw form</span>
          <AiOutlinePushpin className="verify-button-icon" />
        </button>

        <SweetAlert2
          didClose={() => {
            setSwalProps(false);
          }}
          show={swalProps}
          closeButtonAriaLabel="Close"
          title={"Withdraw Form"}
          showCancelButton={false}
          showConfirmButton={false}
        >
          <WithdrawForm
            schoolCode={schoolCode}
            removePopup={removePopup}
            refreshPageData={refreshData}
          />
        </SweetAlert2>

        {school_bonus_transaction?.length > 0 && (
          <BasicTitle
            title={
              "School Transaction (Total " +
              school_bonus_transaction?.length +
              ")"
            }
          />
        )}

        {school_bonus_transaction?.length > 0 ? (
          school_bonus_transaction.map((schoolBonusTransaction, index) => (
            <ListTile
              key={schoolBonusTransaction.schoolCode}
              leading={
                (schoolBonusTransaction?.isWithdraw ? "-" : "+") +
                `${schoolBonusTransaction.amount}`
              }
              title={`Bank No: ${schoolBonusTransaction.bankNo}`}
              subtitle={
                (schoolBonusTransaction?.isWithdraw ? "-" : "+") +
                ` ${schoolBonusTransaction.amount} ETB`
              }
              color={"orange"}
              info={schoolBonusTransaction?.type?.toUpperCase()}
            />
          ))
        ) : (
          <BasicTitle
            style={{
              textAlign: "center",
              margin: "10px 0",
            }}
            title={"No School Bonus Transaction"}
          />
        )}

        <BasicTitle
          title={
            "Students (Total " +
            studentsCount.reduce(
              (acc, grade) => acc + grade?._count?.grade,
              0
            ) +
            ")"
          }
        />

        <InfiniteScroll
          url={"/admin/api/users"}
          query={schoolName}
          dataKey={"users"}
        >
          {function (users, lastElementRef) {
            return users.length === 0 ? (
              <div className="page-loading-wrapper">
                <div className="no-data-wrapper">
                  <BsFillMenuButtonWideFill className="no-data-icon" />

                  <BasicTitle title="There is no user detail in the docket" />
                </div>
              </div>
            ) : (
              <>
                {users.map((user, index) => (
                  <UserDetailCard
                    key={user.uid}
                    user={user}
                    search={schoolName}
                    lastElementRef={
                      index === users.length - 1 ? lastElementRef : null
                    }
                  />
                ))}
              </>
            );
          }}
        </InfiniteScroll>
      </div>
    </div>
  );
}

function Tile({ title, info, icon }) {
  return (
    <div className="profile-info-tile">
      <div>
        {icon}
        <span className="tile-title">{title}:</span>
      </div>
      <span className="tile-data"> {info} </span>
    </div>
  );
}
