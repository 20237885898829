import moment from "moment";

export default function stat(data) {
  const flatGrade8 = returnFlat(data, "grade8");
  const flatGrade10 = returnFlat(data, "grade10");
  const flatGrade12N = returnFlat(data, "grade12n");
  const flatGrade12S = returnFlat(data, "grade12s");

  return {
    flatGrade8,
    flatGrade10,
    flatGrade12N,
    flatGrade12S:returnFlat(data, "grade12s",true),
    count: flatGrade8?.count + flatGrade12N?.count + flatGrade12S?.count,
    main: {
      count:
        flatGrade8?.main?.count +
        flatGrade10?.main?.count +
        flatGrade12N?.main?.count +
        flatGrade12S?.main?.count,
      download:
        flatGrade8?.main?.download +
        flatGrade10?.main?.download +
        flatGrade12N?.main?.download +
        flatGrade12S?.main?.download,
      weekly:
        flatGrade8?.main?.weekly +
        flatGrade10?.main?.weekly +
        flatGrade12N?.main?.weekly +
        flatGrade12S?.main?.weekly,
      monthly:
        flatGrade8?.main?.monthly +
        flatGrade10?.main?.monthly +
        flatGrade12N?.main?.monthly +
        flatGrade12S?.main?.monthly,
    },
    model: {
      count:
        flatGrade8?.model?.count +
        flatGrade10?.model?.count +
        flatGrade12N?.model?.count +
        flatGrade12S?.model?.count,
      download:
        flatGrade8?.model?.download +
        flatGrade10?.model?.download +
        flatGrade12N?.model?.download +
        flatGrade12S?.model?.download,
      weekly:
        flatGrade8?.model?.weekly +
        flatGrade10?.model?.download +
        flatGrade12N?.model?.weekly +
        flatGrade12S?.model?.weekly,
      monthly:
        flatGrade8?.model?.monthly +
        flatGrade10?.model?.monthly +
        flatGrade12N?.model?.monthly +
        flatGrade12S?.model?.monthly,
    },
    others: {
      count:
        flatGrade8?.others?.count +
        flatGrade10?.others?.count +
        flatGrade12N?.others?.count +
        flatGrade12S?.others?.count,
      download:
        flatGrade8?.others?.download +
        flatGrade10?.others?.download +
        flatGrade12N?.others?.download +
        flatGrade12S?.others?.download,
      weekly:
        flatGrade8?.others?.weekly +
        flatGrade10?.others?.weekly +
        flatGrade12N?.others?.weekly +
        flatGrade12S?.others?.weekly,
      monthly:
        flatGrade8?.others?.monthly +
        flatGrade10?.others?.monthly +
        flatGrade12N?.others?.monthly +
        flatGrade12S?.others?.monthly,
    },

    totalDownload:
      flatGrade8?.totalDownload +
      flatGrade10?.totalDownload +
      flatGrade12N?.totalDownload +
      flatGrade12S?.totalDownload,

    weekly: data?.examsFromDB.filter((examFromDB) => {
      return (
        moment(examFromDB.createdAt).isAfter(
          moment(new Date()).subtract({ day: 7 })
        ) > 0
      );
    }).length,
    monthly: data?.examsFromDB.filter((examFromDB) => {
      return (
        moment(examFromDB.createdAt).isAfter(
          moment(new Date()).subtract({ day: 30 })
        ) > 0
      );
    }).length,
  };
}

function returnFlat(data, grade,real) {
  const subjectInformation = [];
  let flatGrade = Object.keys(data.structure.grades[grade])
    .map((subject) => {
      if (
        grade === "grade12s" &&
        ["mathSocial", "mathNatural", "aptitude", "civic", "english"].includes(
          subject
        && !real)
      ) {
        data.structure.grades[grade][subject].exams = [];
      }

      const flatSubject = data.structure.grades[grade][subject]?.exams?.map(
        (exam) => {
          return {
            ...exam,
            grade: grade,
            subject,
            examFromDB: data.examsFromDB.filter(
              (examFromDB) => examFromDB.eid === exam.id
            )[0],
          };
        }
      );

      subjectInformation.push({
        subject,

        main: {
          count: flatSubject.filter((exam) => exam.type === "main")?.length,
          download: flatSubject
            .filter((exam) => exam.type === "main")
            .reduce((acc, curr) => {
              if (curr.commingSoon) {
                return acc;
              }
              return acc + (curr?.examFromDB?.download ?? 0);
            }, 0),
          weekly: flatSubject.filter((exam) => {
            return (
              moment(exam?.examFromDB?.createdAt).isAfter(
                moment(new Date()).subtract({ day: 7 })
              ) > 0 &&
              exam.subject === subject &&
              exam.type === "main"
            );
          }).length,
          monthly: flatSubject.filter((exam) => {
            return (
              moment(exam?.examFromDB?.createdAt).isAfter(
                moment(new Date()).subtract({ day: 30 })
              ) > 0 &&
              exam.subject === subject &&
              exam.type === "main"
            );
          }).length,
        },
        model: {
          count: flatSubject.filter((exam) => exam.type === "model")?.length,
          download: flatSubject
            .filter((exam) => exam.type === "model")
            .reduce((acc, curr) => {
              if (curr.commingSoon) {
                return acc;
              }
              return acc + (curr?.examFromDB?.download ?? 0);
            }, 0),
          weekly: flatSubject.filter((exam) => {
            return (
              moment(exam?.examFromDB?.createdAt).isAfter(
                moment(new Date()).subtract({ day: 7 })
              ) > 0 &&
              exam.subject === subject &&
              exam.type === "model"
            );
          }).length,
          monthly: flatSubject.filter((exam) => {
            return (
              moment(exam?.examFromDB?.createdAt).isAfter(
                moment(new Date()).subtract({ day: 30 })
              ) > 0 &&
              exam.subject === subject &&
              exam.type === "model"
            );
          }).length,
        },
        others: {
          count: flatSubject.filter((exam) => exam.type === "others")?.length,
          download: flatSubject
            .filter((exam) => exam.type === "others")
            .reduce((acc, curr) => {
              if (curr.commingSoon) {
                return acc;
              }
              return acc + (curr?.examFromDB?.download ?? 0);
            }, 0),
          weekly: flatSubject.filter((exam) => {
            return (
              moment(exam?.examFromDB?.createdAt).isAfter(
                moment(new Date()).subtract({ day: 7 })
              ) > 0 &&
              exam.subject === subject &&
              exam.type === "others"
            );
          }).length,
          monthly: flatSubject.filter((exam) => {
            return (
              moment(exam?.examFromDB?.createdAt).isAfter(
                moment(new Date()).subtract({ day: 30 })
              ) > 0 &&
              exam.subject === subject &&
              exam.type === "others"
            );
          }).length,
        },

        totalDownload: flatSubject.reduce((acc, curr) => {
          if (curr.commingSoon) {
            return acc;
          }
          return acc + (curr?.examFromDB?.download ?? 0);
        }, 0),
        count: flatSubject.length,
        weekly: flatSubject.filter((exam) => {
          return (
            moment(exam?.examFromDB?.createdAt).isAfter(
              moment(new Date()).subtract({ day: 7 })
            ) > 0 && exam.subject === subject
          );
        }).length,
        monthly: flatSubject.filter((exam) => {
          return (
            moment(exam?.examFromDB?.createdAt).isAfter(
              moment(new Date()).subtract({ day: 30 })
            ) > 0 && exam.subject === subject
          );
        }).length,
      });

      return flatSubject;
    })
    .flatMap((arr) => arr);
  const chunkOfIds = [...new Set(flatGrade.map((exam) => exam.id))];
  flatGrade = chunkOfIds.map(
    (examId) => flatGrade.filter((exam) => exam.id === examId)[0]
  );
  // console.log(flatGrade);
  return {
    main: {
      count: flatGrade.filter((exam) => exam.type === "main")?.length,
      download: flatGrade
        .filter((exam) => exam.type === "main")
        .reduce((acc, curr) => {
          if (curr.commingSoon) {
            return acc;
          }
          return acc + (curr?.examFromDB?.download ?? 0);
        }, 0),
      weekly: subjectInformation.reduce(
        (acc, curr) => acc + curr?.main?.weekly,
        0
      ),
      monthly: subjectInformation.reduce(
        (acc, curr) => acc + curr?.main?.monthly,
        0
      ),
    },
    model: {
      count: flatGrade.filter((exam) => exam.type === "model")?.length,
      download: flatGrade
        .filter((exam) => exam.type === "model")
        .reduce((acc, curr) => {
          if (curr.commingSoon) {
            return acc;
          }
          return acc + (curr?.examFromDB?.download ?? 0);
        }, 0),
      weekly: flatGrade.filter((exam) => {
        return (
          moment(exam?.examFromDB?.createdAt).isAfter(
            moment(new Date()).subtract({ day: 7 })
          ) > 0 && exam.type === "model"
        );
      }).length,
      monthly: flatGrade.filter((exam) => {
        return (
          moment(exam?.examFromDB?.createdAt).isAfter(
            moment(new Date()).subtract({ day: 30 })
          ) > 0 && exam.type === "model"
        );
      }).length,
    },
    others: {
      count: flatGrade.filter((exam) => exam.type === "others")?.length,
      download: flatGrade
        .filter((exam) => exam.type === "others")
        .reduce((acc, curr) => {
          if (curr.commingSoon) {
            return acc;
          }
          return acc + (curr?.examFromDB?.download ?? 0);
        }, 0),
      weekly: flatGrade.filter((exam) => {
        return (
          moment(exam?.examFromDB?.createdAt).isAfter(
            moment(new Date()).subtract({ day: 7 })
          ) > 0 && exam.type === "others"
        );
      }).length,
      monthly: flatGrade.filter((exam) => {
        return (
          moment(exam?.examFromDB?.createdAt).isAfter(
            moment(new Date()).subtract({ day: 30 })
          ) > 0 && exam.type === "others"
        );
      }).length,
    },
    weekly: flatGrade.filter((exam) => {
      return (
        moment(exam?.examFromDB?.createdAt).isAfter(
          moment(new Date()).subtract({ day: 7 })
        ) > 0
      );
    }).length,
    monthly: flatGrade.filter((exam) => {
      return (
        moment(exam?.examFromDB?.createdAt).isAfter(
          moment(new Date()).subtract({ day: 30 })
        ) > 0
      );
    }).length,
    flat: flatGrade,
    count: flatGrade.length,
    totalDownload: flatGrade.reduce((acc, curr) => {
      if (curr.commingSoon) {
        return acc;
      }
      return acc + (curr?.examFromDB?.download ?? 0);
    }, 0),
    subjectInformation,
  };
}
